import React from 'react';
import './FormStyle.css';  // Assuming you have CSS to style this

function ShortTextInput({ label, value, onChange, buttonLabel, onSubmit }) {
    return (
        <div className="form-input-container">
            <div className="input-group">
                <label className="input-label">{label}</label>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="input-field"
                />
            </div>
            <button onClick={onSubmit} className="form-submit-button">
                {buttonLabel}
            </button>
        </div>
    );
}

export default ShortTextInput;

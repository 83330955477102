import React from 'react';
import {Box, Container} from '@mui/material';

function ComingSoon() {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
            }}
        >
            <Box
                component="iframe"
                src="https://www.canva.com/design/DAGVWWaEKC0/U8D3S84q14wrmwtm0hi5Ug/view?embed"
                loading="lazy"
                allowFullScreen
                sx={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            />
        </Container>
    );
}

export default ComingSoon;

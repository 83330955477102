import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import SegmentCard from "../Components/SegmentCard";
import {Box, Typography, Grid, Divider, IconButton} from '@mui/material';
import TimePicker from "./Components/TimePicker";
import NextStepButton from "../Components/NextStepButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Assuming you will place the CSS file in the same directory

function PostRideSetTimes() {
    const location = useLocation();
    const { formattedDate, numberOfPassengers, rideDescription, segments, roundTrip } = location.state || {};
    const [timeSegments, setTimeSegments] = useState(segments);

    // Handler for time selection
    const handleTimeChange = (index, type, event) => {
        const updatedSegments = [...segments];
        updatedSegments[index][type] = event.target.value;
        setTimeSegments(updatedSegments);
        console.log("new segment data : ", timeSegments);
    };

    return (
        <Box sx={{ padding: 0, bgcolor: 'transparent', boxShadow: 'none', mx: 2 }}>
            {/* Navigate-back Arrow */}
            <IconButton
                sx={{
                    position: 'relative',
                    top: 0,
                    alignSelf: 'flex-start',
                    mb: 1,
                }}
                onClick={() => window.history.back()}
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography variant="h4" sx={{ mb: 1 }}>
                Set times
            </Typography>

            {/* Subtitle text */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Select the estimated departure and arrival times for your trip.
            </Typography>

            {/* Divider line below the subtitle */}
            <Divider sx={{ mb: 3 }} />

            {timeSegments.map((segment, index) => (
                <Box
                    key={index}
                    sx={{
                        padding: 0,
                        marginBottom: 4, // Increased margin between leg sections
                        boxShadow: 'none', // Remove drop shadow
                    }}
                >
                    <SegmentCard segment={segment} index={index + 1} />

                    {/* Time input fields with conditional border color */}
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    border: segment.departureTime ? '1px solid' : 1, // Full border when empty
                                    borderColor: 'primary.main',
                                    borderTop: segment.departureTime ? 'none' : '1px solid', // No top border when filled
                                    borderRadius: 0, // No rounded corners
                                    p: 0, // No padding
                                }}
                            >
                                <TimePicker
                                    label="Departure Time"
                                    value={segment.departureTime}
                                    onChange={(event) => handleTimeChange(index, 'departureTime', event)}
                                    fullWidth
                                    minTime={index > 0 ? timeSegments[index - 1].arrivalTime || timeSegments[index - 1].departureTime : undefined}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    border: segment.arrivalTime ? '1px solid' : 1, // Full border when empty
                                    borderColor: 'primary.main',
                                    borderTop: segment.arrivalTime ? 'none' : '1px solid', // No top border when filled
                                    borderRadius: 0, // No rounded corners
                                    p: 0, // No padding
                                }}
                            >
                                <TimePicker
                                    label="Arrival Time"
                                    value={segment.arrivalTime}
                                    onChange={(event) => handleTimeChange(index, 'arrivalTime', event)}
                                    fullWidth
                                    minTime={segment.departureTime} // Arrival time cannot be earlier than departure
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Divider between segments */}
                    {index < timeSegments.length - 1 && (
                        <Divider sx={{ my: 2 }} />
                    )}
                </Box>
            ))}

            {/* Right-aligned Next Step Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0, mb: 3 }}>
                <NextStepButton
                    label="Next Step"
                    redirectPath="/post-ride-set-prices"
                    locations={[]}
                    formattedDate={formattedDate}
                    numberOfPassengers={numberOfPassengers}
                    rideDescription={rideDescription}
                    segments={timeSegments}
                    roundTrip={roundTrip}
                />
            </Box>
        </Box>
    );
}

export default PostRideSetTimes;

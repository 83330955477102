import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ReserveNowBar() {
    const navigate = useNavigate();

    const handleReserveClick = () => {
        navigate('/success');
    };

    return (
        <Box
            sx={{
                bottom: 0,
                left: 0,
                right: 0,
                marginTop: 2,
                padding: 2,
                backgroundColor: 'transparent', // No visible background
                boxShadow: 'none', // Remove shadow
                display: 'flex',
                justifyContent: 'center',
                zIndex: 1200,
            }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleReserveClick}
                sx={{
                    width: '100%',
                    padding: '12px',
                    fontSize: '1rem',
                    borderRadius: '50px', // Pill-shaped button
                    textTransform: 'none',
                    boxShadow: 'none', // Remove initial shadow
                    '&:hover': {
                        backgroundColor: 'primary.main', // Change background color to primary.main on hover
                        color: 'primary.contrastText', // Change text color to primary.contrastText on hover
                        boxShadow: 'none', // Keep shadow off
                    },
                }}
            >
                Reserve Now
            </Button>
        </Box>
    );
}

export default ReserveNowBar;

import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { Box, Button, Typography, CircularProgress, Alert } from '@mui/material';
import {useAuth0} from "@auth0/auth0-react";
import apiConfig from "../../../config/apiConfig";

function PostRideConfirmation() {
    const { user, isAuthenticated, isLoading } = useAuth0(); // Use the Auth0 hook to get the user
    const [driver_id, setDriver_id] = useState(null); // State to store driver ID
    const [message, setMessage] = useState(''); // State to store success/error message
    const location = useLocation();
    const {
        formattedDate,
        numberOfPassengers,
        rideDescription,
        segments,
        roundTrip
    } = location.state || {};

    // Fetch the user's profile data from your backend
    const fetchUserProfile = async (auth0_user_id) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Specify that we are sending JSON
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ auth0_user_id }), // Send the user ID in the request body
            });

            if (response.ok) {
                const data = await response.json();
                setDriver_id(data.profile_id); // Store the profile data in the state
            } else {
                throw new Error('Failed to fetch profile data.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            setMessage('An error occurred while fetching the profile data.');
        }
    };

    // Function to create the ride and segments
    const createRideAndSegments = async (driver_id) => {
        try {
            console.log("creating ride and segments...");
            const response = await fetch(`${apiConfig.baseUrl}/api/rides/create-ride-and-segments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    driver_id,
                    formattedDate,
                    numberOfPassengers,
                    rideDescription,
                    segments,
                    round_trip: roundTrip
                })
            });

            if (response.status === 409) {
                setMessage('This ride already exists'); // Set the message for ride duplication
            } else if (response.ok) {
                setMessage('Ride Created Successfully'); // Success message
            } else {
                throw new Error('Failed to create ride.');
            }
        } catch (error) {
            console.error('Error creating ride and segments:', error);
            setMessage('There was an error. Please try again'); // Error message
        }
    };

    const navigate = useNavigate();

    const handleReturnHome = () => {
        navigate('/home');
    };

    // Effect to fetch the user profile and create the ride
    useEffect(() => {
        console.log("page loading...");
        if (!isLoading && isAuthenticated) {
            // First, fetch the user profile to get the driver_id
            console.log("fetching driver id");
            fetchUserProfile(user.sub).then(() => {
                // Once the driver_id is obtained, create the ride and segments
                if (driver_id) {
                    createRideAndSegments(driver_id);
                }
            });
        }
    }, [isLoading, isAuthenticated, createRideAndSegments, user, driver_id]); // Make sure to include driver_id to re-trigger the effect when it's set

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                pt: 5, // Add top padding for spacing
                paddingX: 4,
                backgroundColor: 'background.paper', // White/paper color
            }}
        >
            {/* Centered Image with Top Margin */}
            <Box
                component="img"
                src="/content/custom_graphics/ride_with_friends.png"
                alt="Ride with Friends"
                sx={{ width: '150px', height: 'auto', mb: 4 }}
            />

            {/* Conditional Message or Loading Indicator */}
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                {message ? (
                    <Alert severity="success" sx={{ mb: 4 }}>
                        {message}
                    </Alert>
                ) : (
                    <Box>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Creating your ride...</Typography>
                    </Box>
                )}
            </Box>

            {/* Return to Home Page and View My Rides Buttons */}
            {message && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons on mobile
                        gap: 2,
                        width: '100%',
                        maxWidth: 400,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReturnHome}
                        sx={{ flex: 1, py: 1.5 }} // Full width for each button
                    >
                        Return to Home
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.href = '/view-rides-driver'}
                        sx={{ flex: 1, py: 1.5 }}
                    >
                        View My Rides
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default PostRideConfirmation;

import { useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    Divider,
    Container,
    IconButton,
    useMediaQuery,
    Link,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddMessage from './review_components/AddMessage';
import ReserveNowBar from './review_components/ReserveNowBar';
import LocationDetailsCard from "../RideDetails/ride_detail_components/LocationDetailsCard";
import {formatDate} from "../../utils/dateUtils";
import { useNavigate } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useState} from "react";

function ReviewPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { segmentGroup, driverDetails } = location.state || {};
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Use media query for responsiveness

    const [acceptedTerms, setAcceptedTerms] = useState(false); // State to track whether terms are accepted

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setAcceptedTerms(event.target.checked);
    };

    // Ensure segmentGroup is available
    if (!segmentGroup) {
        return <Typography variant="body1">No segment data available</Typography>;
    }

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <Container maxWidth="lg" sx={{ padding: 2 }}>
            {segmentGroup && (
                <Box>
                    {/* Back Arrow Icon */}
                    <IconButton
                        onClick={handleGoBack}
                        aria-label="go-back"
                        sx={{
                            color: 'primary.main',
                            marginBottom: 2,
                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: 'primary.dark',
                            },
                        }}
                    >
                        <ArrowBackIcon sx={{ fontSize: '24px' }} />
                    </IconButton>

                    {/* Title Section with Responsive Price Position */}
                    <Box sx={{ marginBottom: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                marginBottom: 1,
                                width: '100%',
                                justifyContent: isMobile ? 'flex-start' : 'space-between', // Left-align on mobile, space-between on desktop
                                alignItems: 'center',
                                flexDirection: isMobile ? 'column' : 'row', // Column for mobile, row for desktop
                            }}
                        >
                            {/* Confirm Your Ride Header */}
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                    marginBottom: isMobile ? 0 : 0,
                                    textAlign: 'left', // Ensure left alignment for mobile
                                    width: '100%', // Ensure header takes full width on mobile
                                }}
                            >
                                Confirm your ride
                            </Typography>

                            {/* Price Info - Right-aligned for desktop, below title for mobile */}
                            <Typography
                                variant="h4"
                                sx={{
                                    color: 'primary.main',
                                    fontWeight: 'bold',
                                    marginTop: isMobile ? 0 : 0, // Add margin-top on mobile
                                    textAlign: 'left', // Left-align on mobile
                                    width: isMobile ? '100%' : 'auto', // Full width on mobile, auto width on desktop
                                }}
                            >
                                ${segmentGroup.price}
                            </Typography>
                        </Box>

                        {/* Date Info with Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            <CalendarTodayIcon sx={{ color: 'text.primary', marginRight: 1 }} /> {/* Calendar Icon */}
                            <Typography variant="h7" sx={{ color: 'text.primary' }}>
                                {formatDate(segmentGroup.date)}
                            </Typography>
                        </Box>

                        {/* Round Trip or One-Way Info with Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                            {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) ? (
                                <SwapHorizIcon sx={{ color: 'text.primary', marginRight: 1 }} /> // Round Trip Icon
                            ) : (
                                <ArrowForwardIcon sx={{ color: 'text.primary', marginRight: 1 }} /> // One-Way Icon
                            )}
                            <Typography variant="h7" sx={{ color: 'text.primary' }}>
                                {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) ? 'Round Trip' : 'One-Way'}
                            </Typography>
                        </Box>

                        {/* Display the divider only when round_trip is "false" */}
                        {(segmentGroup.round_trip === "false" || segmentGroup.round_trip === false) && (
                            <Divider sx={{ width: '100%', marginBottom: 2, backgroundColor: 'primary.main' }} />
                        )}
                    </Box>

                    {/* Outbound Journey - Only visible if it's a round trip */}
                    {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            {/* Divider line on the left of the text */}
                            <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main', marginRight: 1 }} />

                            {/* The text itself */}
                            <Typography variant="h7" sx={{ marginRight: 1 }}>
                                Outbound Journey
                            </Typography>

                            {/* Divider line on the right of the text */}
                            <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main' }} />
                        </Box>
                    )}

                    {/* Departure Location */}
                    <LocationDetailsCard
                        initial_text="Departing at"
                        address={segmentGroup.departure_info.address}
                        city={segmentGroup.departure_info.city}
                        time={segmentGroup.departure_info.time}
                    />
                    <LocationDetailsCard
                        initial_text="Arriving at"
                        address={segmentGroup.arrival_info.address}
                        city={segmentGroup.arrival_info.city}
                        time={segmentGroup.arrival_info.time}
                    />

                    {/* Conditional rendering of return LocationDetailsCard if round_trip is true */}
                    {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) && (
                        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                            {/* "Return Info" text centered on top of the divider */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                {/* Divider line on the left of the text */}
                                <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main', marginRight: 1 }} />

                                {/* The text itself */}
                                <Typography variant="h7" sx={{ marginRight: 1 }}>
                                    Return Journey
                                </Typography>

                                {/* Divider line on the right of the text */}
                                <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main' }} />
                            </Box>

                            {/* Return Location Details */}
                            <LocationDetailsCard
                                initial_text="Return at"
                                address={segmentGroup.departure_info.address}
                                city={segmentGroup.departure_info.city}
                                time={segmentGroup.departure_info.time}
                            />
                            <LocationDetailsCard
                                initial_text="Arriving at"
                                address={segmentGroup.arrival_info.address}
                                city={segmentGroup.arrival_info.city}
                                time={segmentGroup.arrival_info.time}
                            />
                        </Box>
                    )}

                    {/* Message Driver Component */}
                    <AddMessage
                        driverFirstName={driverDetails.first_name}
                    />
                </Box>
            )}

            {/* Terms and Conditions Checkbox */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',  // Horizontally center the content
                    textAlign: 'center',       // Ensure text is centered
                    alignItems: 'center',      // Vertically center the content
                    marginTop: 3,
                    marginBottom: 3
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={acceptedTerms}
                            onChange={handleCheckboxChange}
                            name="acceptTerms"
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                            I have read and accept RideAlong's&nbsp;
                            <Link href="/terms-and-conditions" target="_blank" sx={{ color: 'primary.main' }}>
                                Terms and Conditions
                            </Link>
                            ,&nbsp;
                            <Link href="/privacy-policy" target="_blank" sx={{ color: 'primary.main' }}>
                                Privacy Policy
                            </Link>
                            , and other relevant policies.
                        </Typography>
                    }
                />
            </Box>

            {/* Reserve Now Bar (Fixed at the bottom of the screen) */}
            <ReserveNowBar segmentGroup={segmentGroup} />
        </Container>
    );
}

export default ReviewPage;

import React, { useEffect, useRef, useState, forwardRef } from 'react';
import SchoolIcon from '@mui/icons-material/School';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import HistoryIcon from '@mui/icons-material/History';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StoreIcon from '@mui/icons-material/Store';
import CheckIcon from '@mui/icons-material/Check';
import { useProfile } from '../../context/ProfileContext';  // Import the custom hook
import './SearchInput.css';
import apiConfig from "../../config/apiConfig";

const SearchInput = forwardRef(({ placeholder, address, handleAddressChange, mode = "wide", onItemClick, enableHistory = false }, ref) => {
    const [localDropdownItems, setLocalDropdownItems] = useState([]); // Use local state to manage dropdown items within this component
    const [showDropdown, setShowDropdown] = useState(false);
    const localRef = useRef(null); // Always create a local ref
    const inputRef = ref || localRef; // Use ref from parent or fallback to
    const { profile_id } = useProfile();  // Access profileId from context

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Focus the input field when the component mounts
        }
    }, [inputRef]);

    const fetchLocationResults = async (searchText, enableHistory, profile_id, mode) => {
        const searchType = "both";
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/geo/get-location-results`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089' // Replace with your method of accessing the key
                },
                body: JSON.stringify({ searchText, mode, searchType, enableHistory, profile_id }) // Send searchText and validated mode
            });

            if (!response.ok) {
                throw new Error('Error fetching location results');
            }

            return await response.json(); // Assuming data is an array of location results
        } catch (error) {
            console.error('Error during fetchLocationResults:', error);
            return []; // Return an empty array on error
        }
    };

    useEffect(() => {
        // Set up a debounce timer
        const debounceTimeout = setTimeout(async () => {
            if (address && address.length >= 3) {
                try {
                    console.log("current profile_id is : ", profile_id);
                    const results = await fetchLocationResults(address, enableHistory, profile_id, mode); // Call the new API handler function
                    setLocalDropdownItems(results); // Set the local state with the fetched results
                    if (inputRef.current && document.activeElement === inputRef.current) {
                        setShowDropdown(true);
                    }
                } catch (error) {
                    console.error("Error fetching location results:", error);
                    setLocalDropdownItems([]); // Clear local state on error
                    setShowDropdown(false);
                }
            } else {
                setLocalDropdownItems([]); // Clear local state if input is less than 3 characters
                setShowDropdown(false);
            }
        }, 1); // Delay of 0.25 seconds

        // Cleanup function to cancel the debounce if address changes
        return () => clearTimeout(debounceTimeout);
    }, [address, enableHistory, mode, profile_id, inputRef]); // occurs when typing / text changes in input box

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === 'Tab' || event.key === 'Enter') && showDropdown && localDropdownItems && localDropdownItems.length > 0) {
                // Prevent the default action for Tab and Enter to allow custom handling
                event.preventDefault();

                // Run processItemSelection with the first dropdown item
                processItemSelection(localDropdownItems[0]);
            }
        };

        if (inputRef.current) {
            inputRef.current.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup the event listener when the component unmounts or dependencies change
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [showDropdown, localDropdownItems, inputRef]);

    // Helper function to process item selection
    const processItemSelection = (item) => {
        let name = "";
        if (item.name){
            name = item.name;
        } else {
            name = null;
        }

        const place = item.place;
        const state = item.state;
        const newAddress = item.type === "place"
            ? `${place}, ${state}`
            : `${item.name}, ${place}, ${state}`;

        // Update the parent component with the selected values
        handleAddressChange({
            address: newAddress,
            name: name,
            place: place,
            state: state,
            type: item.type,
        });

        // If onItemClick exists, trigger it
        if (onItemClick) {
            onItemClick({
                address: newAddress,
                name: name,
                place: place,
                state: state
            });
        }

        setShowDropdown(false); // Hide the dropdown
    };

    // Handle blur event
    const handleBlur = () => {
        if (Array.isArray(localDropdownItems) && localDropdownItems[0]) {
            processItemSelection(localDropdownItems[0]);  // Reuse logic
        }
    };

    // Handle item click
    const handleItemClick = (item) => {
        setLocalDropdownItems([item]);  // Update local state
        processItemSelection(item);     // Reuse logic
    };

    // This handles the input change (typing in the input field)
    const handleInputChange = (event) => {
        const newAddress = event.target.value;
        handleAddressChange({ address: newAddress });  // Send new address up to parent
    };

    return (
        <div className="city-input-container">
            <input
                ref={inputRef}
                type="text"
                value={address}
                onChange={handleInputChange}
                className="search-input city-input"
                placeholder={placeholder}
                onFocus={() => setShowDropdown(Array.isArray(localDropdownItems) && localDropdownItems.length > 0)}
                onBlur={handleBlur}
            />
            {showDropdown && Array.isArray(localDropdownItems) && localDropdownItems.length > 0 && (
                <ul className="search-input-dropdown">
                    {localDropdownItems.map((item, index) => (
                        <li
                            key={index}
                            className="dropdown-item"
                            onMouseDown={() => handleItemClick(item)} // use onMouseDown to avoid losing focus before click
                        >
                            <div className="dropdown-icon">
                                {/* Conditional rendering of icons based on type */}
                                {item.type === 'University' && <SchoolIcon/>}
                                {item.type === 'Ski Resort' && <DownhillSkiingIcon/>}
                                {item.type === 'Historical' && <HistoryIcon/>}
                                {item.type === 'Partner' && <StoreIcon/>}
                                {/* Default icon (Map Pin) if none of the above matches */}
                                {!['University', 'Ski Resort', 'Historical', 'Partner'].includes(item.type) && <LocationOnIcon/>}
                            </div>
                            <div className="dropdown-content">
                                <div className="dropdown-main-text">{item.name}</div>
                                <div className="dropdown-sub-text">{item.place}, {item.state}</div>

                                {/* Conditionally render the third line with a green check mark for Partner type */}
                                {item.type === 'Partner' && (
                                    <div className="dropdown-sub-text" style={{ color: '#4CAF50', display: 'flex', alignItems: 'center' }}>
                                        <CheckIcon sx={{ fontSize: 16, marginRight: 0.5, color: '#4CAF50' }} /> {/* Small green check mark */}
                                        Preferred Pick-up Location
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});

export default SearchInput;

import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Avatar, Typography, CircularProgress} from '@mui/material';
import MessageDriver from './MessageDriver';
import StarIcon from '@mui/icons-material/Star';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // Car icon for Verified Driver
import SchoolIcon from '@mui/icons-material/School';
import apiConfig from "../../../config/apiConfig"; // University student icon

function DriverDetailsCard({ driver, description }) {
    const navigate = useNavigate(); // Initialize navigate function
    const [averageRating, setAverageRating] = useState(null); // State for average rating
    const [completedRides, setCompletedRides] = useState(null); // State for completed rides
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchDriverRating = async () => {
            try {
                // Fetch driver review data (average rating and review count)
                const response = await fetch(`${apiConfig.baseUrl}/api/reviews/get-driver-review-data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: driver.profile_id }), // Use driver.profile_id to get the rating
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch driver rating');
                }

                const { averageRating } = await response.json(); // Fetch average rating from response
                setAverageRating(averageRating); // Set the average rating
            } catch (err) {
                setError(err.message); // Handle any errors
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        const fetchCompletedRides = async () => {
            try {
                // Fetch the number of completed rides
                const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-completed-rides`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: driver.profile_id }), // Use driver.profile_id for the request
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch completed rides');
                }

                const { completedRides } = await response.json(); // Get the number of completed rides
                setCompletedRides(completedRides); // Set the completed rides in state
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDriverRating(); // Fetch the driver rating
        fetchCompletedRides(); // Fetch the number of completed rides
    }, [driver.profile_id]); // Runs when driver.profile_id changes

    // Handle the click event to navigate to /view-profile with driver object
    const handleCardClick = () => {
        navigate('/view-profile', { state: { profile: driver } });
    };

    // If still loading, return a styled "Loading..." message
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress color="primary" />
                <Typography sx={{ marginLeft: 2, fontWeight: 'bold' }}>Loading...</Typography>
            </Box>
        );
    }

    // If there's an error, return a styled error message
    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>Error Accessing Driver Data: {error}</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: 2,
                flexDirection: 'column', // Ensure everything is stacked vertically
            }}
        >
            {/* Driver Profile Section */}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                <Avatar
                    src={`${driver?.profile_picture || 'http://localhost:5000/uploads/profile_pictures/default.jpeg'}?t=${new Date().getTime()}`}
                    alt="Driver Profile"
                    sx={{
                        width: 125,
                        height: 125,
                        borderRadius: 2,
                        objectFit: 'cover',
                        marginRight: 2,
                        marginTop: 1,
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        flexGrow: 1,
                        alignItems: 'flex-start',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {/* Clickable Driver Name + Arrow */}
                        <Typography
                            variant="h6"
                            onClick={handleCardClick}
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                padding: '4px 12px',
                                borderRadius: '20px',
                                border: '2px solid transparent',
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                    outline: 'none',
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    '& > .arrow': {
                                        color: 'primary.main',
                                    },
                                },
                                transition: 'background-color 0.3s ease, border 0.3s ease',
                            }}
                        >
                            {driver.first_name}
                            <Typography
                                component="span"
                                className="arrow"
                                sx={{
                                    marginLeft: 1,
                                    color: 'secondary.main',
                                    display: 'flex',
                                }}
                            >
                                &gt;
                            </Typography>
                        </Typography>
                    </Box>

                    {/* Driver Rating or No Reviews Yet */}
                    {averageRating ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 0,
                                marginLeft: 2,
                            }}
                        >
                            <StarIcon sx={{ fontSize: 16, color: 'primary.main', marginRight: 1.5 }} />
                            <Typography variant="body2" sx={{ color: 'primary.dark', fontWeight: 'bold' }}>
                                {averageRating} - {completedRides} rides completed
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', marginBottom: 1, marginLeft: 2, width: '100%' }}
                        >
                            No Completed Rides
                        </Typography>
                    )}

                    {/* Verified University Student (if applicable) */}
                    {driver.verified_student && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0, marginLeft: 2 }}>
                            <SchoolIcon sx={{ fontSize: 16, marginRight: 1.5, color: 'primary' }} />
                            <Typography variant="body2" sx={{ color: 'success.main', fontWeight: 'bold', marginLeft: 0 }}>
                                Verified Student
                            </Typography>
                        </Box>
                    )}

                    {/* Verified Driver (if applicable) */}
                    {driver.verified_id && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, marginLeft: 2 }}>
                            <DirectionsCarIcon sx={{ fontSize: 16, marginRight: 1.5, color: 'primary' }} />
                            <Typography variant="body2" sx={{ color: 'success.main', fontWeight: 'bold', marginLeft: 0 }}>
                                Verified Driver
                            </Typography>
                        </Box>
                    )}

                    {/* Message Driver Button */}
                    <Box sx={{ width: '100%', marginLeft: 0 }}>
                        <MessageDriver driverFirstName={driver.first_name} otherUserProfile={driver} />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    marginTop: 3,
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography variant="h7" sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 1 }}>
                    {driver.first_name} says:
                </Typography>
                <Box
                    sx={{
                        display: 'flex', // Flexbox to align the quote line and text
                        borderLeft: `2px solid`, // Vertical line from the theme
                        paddingLeft: 2, // Space between the line and text
                        marginLeft: 1, // Align with the subheader
                    }}
                >
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default DriverDetailsCard;

import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';

const ProfileRatingTile = ({ label, rating }) => {
    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: 'secondary.main', // Background color
                borderRadius: '8px', // Rounded corners
                textAlign: 'center',
                boxShadow: 'none', // Remove any box shadow
            }}
        >
            <Typography variant="subtitle1" gutterBottom color="text.primary">
                {label}
            </Typography>
            <Grid2 container justifyContent="center" alignItems="baseline">
                <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', mr: 0.5, color: 'text.primary' }}>
                    {rating}
                </Typography>
                <Typography variant="h6" component="span" color="text.secondary">
                    /5
                </Typography>
            </Grid2>
        </Box>
    );
};


export default ProfileRatingTile;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import {Box, Card, CardContent, Typography, Avatar} from '@mui/material'; // Import MUI components
import SendIcon from '@mui/icons-material/Send'; // Import the paper airplane icon
import apiConfig from "../../../config/apiConfig";

function InboxCard({ conversation, currentUserProfileID }) {
    const [otherUserProfile, setOtherUserProfile] = useState(null); // Store the other user's profile
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate(); // Initialize useNavigate

    // Fetch the other user's profile using their profile_id
    const fetchOtherUserProfile = async (profile_id) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ profile_id }), // Send the other user's profile_id
            });

            if (response.ok) {
                const data = await response.json();
                setOtherUserProfile(data); // Store the other user's profile data
                setLoading(false);
            } else {
                throw new Error('Failed to fetch the other user\'s profile.');
            }
        } catch (error) {
            console.error('Error fetching other user profile:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        let otherUserProfileID = conversation.sender_profile_id;
        if (currentUserProfileID === conversation.sender_profile_id) {
            otherUserProfileID = conversation.receiver_profile_id;
        }

        fetchOtherUserProfile(otherUserProfileID).then(r => {}); // Fetch the other user's profile
    }, [currentUserProfileID, conversation]);

    if (loading) return <div>Loading...</div>;

    // Handle navigation to the messaging page
    const handleCardClick = () => {
        navigate('/messages', {
            state: {
                otherUserProfile,
                currentUserProfileID
            }
        });
    };

    return (
        <Card
            onClick={handleCardClick}
            sx={{
                marginBottom: '16px',
                marginLeft: '8px',
                marginRight: '8px',
                cursor: 'pointer',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                transition: '0.3s',
                '&:hover': {
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                },
            }}
        >
            {otherUserProfile && (
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Left section with profile picture and text */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Circular profile picture */}
                        <Avatar
                            src={otherUserProfile?.profile_picture || '/default-profile-pic.jpg'}
                            alt={otherUserProfile.first_name}
                            sx={{
                                width: 56,
                                height: 56,
                                marginRight: 2, // Add some spacing between the picture and text
                            }}
                        />

                        {/* Text information */}
                        <Box>
                            <Typography variant="h6" component="h3">
                                {otherUserProfile.first_name}
                            </Typography>

                            {/* Message content: Check if current user is the sender */}
                            <Typography variant="body1" color="textSecondary">
                                {currentUserProfileID === conversation.sender_profile_id
                                    ? `You: ${conversation.message_content}`
                                    : `${otherUserProfile.first_name}: ${conversation.message_content}`}
                            </Typography>

                            {/* Message timestamp */}
                            <Typography variant="caption" color="textSecondary">
                                {conversation.time_formatted}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Paper airplane icon on the right */}
                    <SendIcon sx={{ color: 'primary.main', fontSize: '36px' }} />
                </CardContent>
            )}
        </Card>
    );
}

export default InboxCard;

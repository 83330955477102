import React from 'react';
import { Box, Typography } from '@mui/material'; // Importing necessary MUI components

function SegmentCard({ segment, index, mode = "simple" }) {
    const { departureAddress, arrivalAddress, departureTime, arrivalTime, price } = segment;

    return (
        <Box
            sx={{
                p: 0, // Remove padding for flat appearance
                border: 'none', // No border
                borderRadius: 0, // No rounded corners
                mb: 2, // Margin bottom between segments
                boxShadow: 'none', // Remove drop shadow
                width: '100%', // Full width within its container
                display: 'flex',
                alignItems: 'center', // Vertical centering for all items
                justifyContent: 'space-between', // Space items across the component
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Left Side with Times */}
                {mode === "detailed" && (
                    <Box sx={{ mr: 2, textAlign: 'right' }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
                            {departureTime}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {arrivalTime}
                        </Typography>
                    </Box>
                )}

                {/* Image Component */}
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    <Box
                        component="img"
                        src="/content/custom_icons/trip.png"
                        alt="Trip Icon"
                        sx={{ width: 12, height: 'auto' }} // Reduced icon size
                    />
                </Box>

                {/* Right Side with Addresses */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
                        {departureAddress}
                    </Typography>
                    <Typography variant="body1">
                        {arrivalAddress}
                    </Typography>
                </Box>
            </Box>

            {/* Price on Far Right */}
            <Box sx={{ ml: 2 }}>
                <Typography variant="h5" color="primary.main">
                    ${price}
                </Typography>
            </Box>
        </Box>
    );
}

export default SegmentCard;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SegmentCard from "../Components/SegmentCard";
import NextStepButton from "../Components/NextStepButton";
import {Box, Typography, Grid, TextField, Divider, IconButton} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function PostRideSetPrices() {
    const location = useLocation();
    const { formattedDate, numberOfPassengers, rideDescription, segments, roundTrip } = location.state || {};
    const [priceSegments, setPriceSegments] = useState(segments);

    // Handler for price input
    const handlePriceChange = (index, event) => {
        const updatedSegments = [...segments];
        updatedSegments[index].price = event.target.value;
        setPriceSegments(updatedSegments);
        console.log("new segment data : ", priceSegments);
    };

    return (
        <Box sx={{ padding: 4, bgcolor: 'transparent', boxShadow: 'none', mx: 2 }}>
            {/* Back Arrow */}
            <IconButton
                sx={{
                    position: 'relative',
                    top: 0,
                    alignSelf: 'flex-start',
                    mb: 1,
                }}
                onClick={() => window.history.back()}
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography variant="h4" sx={{ mb: 1 }}>
                Set a Price
            </Typography>

            {/* Subtitle text */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Set the price for your trip. Use the suggested price to increase the chance of matching with a passenger.
            </Typography>

            {/* Divider below subtitle */}
            <Divider sx={{ mb: 3 }} />

            {priceSegments.map((segment, index) => (
                <Box key={index} sx={{ padding: 0, marginBottom: 4, boxShadow: 'none' }}>
                    <SegmentCard segment={segment} index={index + 1} />

                    {/* Price input field with 4-sided border and floating label */}
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    border: 1,
                                    borderColor: 'primary.main',
                                    borderRadius: 0, // No rounded corners
                                    p: 0, // No padding
                                }}
                            >
                                <TextField
                                    label="Price"
                                    type="number"
                                    step="0.01"
                                    value={priceSegments[index].price || ''}
                                    onChange={(event) => handlePriceChange(index, event)}
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true, // Forces label to float above on focus/populated
                                        sx: {
                                            backgroundColor: 'white', // Background color to overlay above the border
                                            px: 0.5, // Horizontal padding for better positioning
                                            ml: -0.5, // Optional: adjust left margin for alignment with border
                                        },
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': { border: 'none' }, // Remove default border
                                            paddingLeft: 1, // Optional: Adjust inner padding for label spacing
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Divider between segments */}
                    {index < priceSegments.length - 1 && (
                        <Divider sx={{ my: 2 }} />
                    )}
                </Box>
            ))}

            {/* Right-aligned Next Step Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <NextStepButton
                    label="Next Step"
                    redirectPath="/post-ride-review"
                    locations={[]}
                    formattedDate={formattedDate}
                    numberOfPassengers={numberOfPassengers}
                    rideDescription={rideDescription}
                    segments={priceSegments}
                    roundTrip={roundTrip}
                />
            </Box>
        </Box>
    );
}

export default PostRideSetPrices;

import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material'; // Importing MUI Button

function NextStepButton({
                            label,
                            redirectPath,
                            locations = [],
                            formattedDate,
                            numberOfPassengers,
                            rideDescription,
                            segments = [],
                            roundTrip
                        }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (locations.length > 1) {
            segments = [];
            for (let i = 0; i < locations.length - 1; i++) {
                // Create a segment object with departure and arrival addresses
                const newSegment = {
                    departureAddress: locations[i].location,
                    arrivalAddress: locations[i + 1].location,
                    departureCity: locations[i].city,
                    arrivalCity: locations[i+1].city,
                    departureState: locations[i].state,
                    arrivalState: locations[i+1].state,
                    price: "", // Empty for now
                    departureTime: "", // Empty for now
                    arrivalTime: "" // Empty for now
                };
                console.log("adding segment on leg 1");
                // Push the new segment into the segments array
                segments.push(newSegment);
            }
            if (roundTrip) {
                for (let i = locations.length - 1; i > 0; i--) {
                    const returnSegment = {
                        departureAddress: locations[i].location,
                        arrivalAddress: locations[i - 1].location,
                        departureCity: locations[i].city,
                        arrivalCity: locations[i - 1].city,
                        departureState: locations[i].state,
                        arrivalState: locations[i - 1].state,
                        price: "", // Empty for now
                        departureTime: "", // Empty for now
                        arrivalTime: "" // Empty for now
                    };
                    console.log("adding segment on leg 2");
                    segments.push(returnSegment);
                }
            }

            console.log("all the segments: ", segments);
        }
    }, [locations, segments]);

    const handleClick = () => {
        navigate(redirectPath, {
            state: {
                formattedDate,
                numberOfPassengers,
                rideDescription,
                segments,
                roundTrip
            }
        });
    };

    return (
        <Button
            variant="contained" // MUI button style
            color="primary"
            onClick={handleClick}
            sx={{ px: 4, py: 1.5, mt: 2 }} // Padding and margin for spacing
        >
            {label}
        </Button>
    );
}

export default NextStepButton;

// src/pages/Home.js
import React from 'react';
import SearchBar from '../../../components/ride_search_bar/SearchBar';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import SavingsIcon from "@mui/icons-material/Savings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NatureIcon from "@mui/icons-material/Nature";
import {useTheme} from "@mui/material/styles"; // Import MUI components

function PostRide() {
    const theme = useTheme(); // Access theme for secondary color

    return (
        <Box sx={{ position: 'relative', zIndex: 1 }}>
            {/* Background Box - Separate container for background */}
            <Box
                sx={{
                    backgroundImage: 'url("/content/home/home_top_section.svg")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    minHeight: '100vh', // Full viewport height, even on larger screens
                    width: '100vw', // Full width of the viewport
                    height: '100%', // Ensure background covers entire page height
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    opacity: 0.4, // Apply opacity to the background
                    zIndex: -1,
                }}
            />

            {/* Foreground Content */}
            <Container maxWidth="md"> {/* Center content and limit width */}
                <Box sx={{ padding: '20px 20px 0' }}>
                    {/* Title Text */}
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.text.primary,
                            marginBottom: '20px',
                            textAlign: 'center',
                        }}
                    >
                        Driving somewhere?  Why not split the costs?
                    </Typography>

                    {/* SearchBar Component */}
                    <SearchBar
                        mode="address"
                        buttonLabel="Create Ride"
                    />

                    {/* Add margin to SearchBar for spacing */}
                    <Box sx={{ marginBottom: { xs: '40px', md: '80px' } }}> {/* Add spacing below SearchBar */}</Box>

                    {/* New Content Section */}
                    <Box
                        sx={{
                            marginTop: { xs: '100px', md: '40px' },
                            maxWidth: { xs: '375px', md: '100%' },
                            margin: { xs: '0 auto', md: '0' },
                        }}
                    >
                        <Grid container spacing={4}>
                            {/* Group 1: Reduce Costs */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <SavingsIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>Reduce Costs</Typography>
                                <Typography variant="body1">
                                    Driving is expensive when considering gas, insurance, maintenance, and more. Carpooling helps you cut costs by around 25% per passenger.
                                </Typography>
                            </Grid>

                            {/* Group 2: You're in Control */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <SupervisorAccountIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>You're in Control</Typography>
                                <Typography variant="body1">
                                    Decide who you share a ride with. Review profiles, messages, and verifications to find compatible passengers.
                                </Typography>
                            </Grid>

                            {/* Group 3: Eco-Friendly Choice */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <NatureIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>Eco-Friendly Choice</Typography>
                                <Typography variant="body1">
                                    Reduce environmental impact by filling empty seats. Every shared trip means fewer emissions per person.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* New Section with Full-Width Ski/Snowboard Section on Top */}
                    <Grid container spacing={4} sx={{ marginTop: '40px'}}>

                        {/* Full-Width Section: Driving Somewhere in Orange Container */}
                        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.quartiary.main, // Orange background for Driving Somewhere
                                    borderRadius: theme.shape.borderRadius,
                                    padding: '20px',
                                }}
                            >
                                <Grid container spacing={4} alignItems="center" justifyContent="center">
                                    {/* Left side: Image */}
                                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                        <Box
                                            component="img"
                                            src="/content/custom_graphics/ride_with_friends.png"
                                            alt="Ride with Friends"
                                            sx={{
                                                maxWidth: { xs: '50%', md: '100%' },
                                                height: 'auto',
                                            }}
                                        />
                                    </Grid>

                                    {/* Right side: Text content with button */}
                                    <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                            Looking for a ride instead?
                                        </Typography>
                                        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                                            Search for rides with drivers going where you're going.
                                        </Typography>

                                        {/* Find a Ride Button */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                borderRadius: '50px',
                                                padding: '10px 30px',
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                            onClick={() => window.location.href = '/home'}
                                        >
                                            Find a ride
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        {/* New Layout with Switched Positions and Rotated Color Schemes */}
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'space-between',
                                    marginTop: 0,
                                }}
                            >
                                {/* 1/3 Section: Ski or Snowboard in Blue Container */}
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.secondary.main, // Blue background for Ski or Snowboard
                                        borderRadius: theme.shape.borderRadius,
                                        padding: '20px',
                                        textAlign: 'center',
                                        flex: '1',
                                        marginRight: { md: '20px' },
                                        marginBottom: { xs: '20px', md: '0' },
                                    }}
                                >
                                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                        Ski or Snowboard?
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginBottom: '22px' }}>
                                        Drive to the mountain, while spending less.
                                    </Typography>

                                    {/* Image Above Button */}
                                    <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                                        <Box
                                            component="img"
                                            src="/content/custom_graphics/snowboarder_group.png"
                                            alt="Snowboarder"
                                            sx={{
                                                maxWidth: { xs: '50%', md: '100%' },
                                                height: 'auto',
                                            }}
                                        />
                                    </Box>

                                    {/* "Ski" Button */}
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Button
                                            color="primary"
                                            sx={{
                                                marginTop: '54px',
                                                borderRadius: '50px',
                                                padding: '10px 30px',
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                            onClick={() => window.location.href = '/mountains'}
                                        >
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>

                                {/* 2/3 Section: Student Friendly in White Container */}
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper, // White background for Student Friendly
                                        borderRadius: theme.shape.borderRadius,
                                        padding: '20px',
                                        textAlign: 'center',
                                        flex: '2',
                                    }}
                                >
                                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                                        {/* Custom Graphic */}
                                        <Grid item xs={12}>
                                            <Box
                                                component="img"
                                                src="/content/custom_graphics/student_group.png"
                                                alt="Student Group"
                                                sx={{
                                                    maxWidth: { xs: '50%', md: '70%' },
                                                    height: 'auto',
                                                }}
                                            />
                                        </Grid>

                                        {/* Text Content */}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                                Student friendly
                                            </Typography>
                                            <Typography variant="body1">
                                                Share your ride home for winter break with other verified students.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {/* Learn More Button */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            marginTop: '28px',
                                            borderRadius: '50px',
                                            padding: '10px 40px',
                                            fontSize: '16px',
                                            textTransform: 'none',
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                        }}
                                        onClick={() => window.location.href = '/student-discounts'}
                                    >
                                        Info for students
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default PostRide;
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Box, Button } from '@mui/material'; // Import MUI components
import SendIcon from '@mui/icons-material/Send'; // Import MUI Send icon


function MessageDriver({ driverFirstName, otherUserProfile }) {
    const navigate = useNavigate(); // Initialize useNavigate for redirection

    const handleMessageClick = () => {
        navigate('/messages', {
            state: {
                otherUserProfile: otherUserProfile
            }
        });
    };

    return (
        <Box sx={{ width: '100%', padding: 0, display: 'flex', justifyContent: 'flex-start' }}>
            <Button
                onClick={handleMessageClick}
                sx={{
                    backgroundColor: 'transparent', // No background in idle state
                    color: 'primary.main',
                    fontSize: 'body1.fontSize',
                    padding: '8px 16px',
                    borderRadius: '50px',
                    border: '2px solid transparent', // Transparent border to avoid size changes during hover
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: 'fit-content', // Ensure button wraps the content
                    '&:hover': {
                        backgroundColor: 'secondary.main',
                        borderColor: 'primary.main', // Show the border during hover
                        boxShadow: 3, // Add shadow during hover
                    },
                    transition: 'background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease',
                }}
            >
                {/* Show only "Message" for screen sizes below 500px */}
                <Box sx={{ display: { xs: 'none', sm: 'inline' } }}> {/* Hide driver's name on smaller screens */}
                    Message {driverFirstName}
                </Box>
                <Box sx={{ display: { xs: 'inline', sm: 'none' } }}> {/* Show only "Message" on smaller screens */}
                    Message
                </Box>
                <SendIcon sx={{ marginLeft: '8px', fontSize: '16px' }} />
            </Button>
        </Box>
    );
}

export default MessageDriver;

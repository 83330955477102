import { supabase } from './supabaseClient';

// Sign up user
export const signUp = async (email, password) => {
    const { user, error } = await supabase.auth.signUp({ email, password });
    if (error) throw error;
    return user;
};

// Sign in user
export const signIn = async (email, password) => {
    const { user, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    return user;
};

// Sign out user
export const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
};

// Sign in with Google
export const signInWithGoogle = async () => {
    const { user, session, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
    });
    if (error) throw error;
    return { user, session };
};


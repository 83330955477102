import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const ProfileTotalRidesTile = ({ label, ridesCompleted }) => {
    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: 'secondary.main', // Background color
                borderRadius: '8px', // Rounded corners
                textAlign: 'center',
                boxShadow: 'none', // Remove any box shadow
            }}
        >
            <Typography variant="subtitle1" gutterBottom color="text.primary">
                {label}
            </Typography>
            <Grid container justifyContent="center" alignItems="baseline">
                <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                    {ridesCompleted}
                </Typography>
            </Grid>
        </Box>
    );
};

export default ProfileTotalRidesTile;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SegmentCard from "../Components/SegmentCard";
import NextStepButton from "../Components/NextStepButton";
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Divider,
    Slider,
    Modal, Button, Link, Checkbox
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function PostRideReview() {
    const location = useLocation();
    const { formattedDate, segments, roundTrip } = location.state || {};

    // State for max passengers and ride description
    const [maxPassengers, setMaxPassengers] = useState(1);
    const [rideDescription, setRideDescription] = useState("");

    const [termsOpen, setTermsOpen] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const handleTermsOpen = () => setTermsOpen(true);
    const handleTermsClose = () => setTermsOpen(false);

    // Handler for passenger selection
    const handlePassengerSelection = (value) => {
        setMaxPassengers(value);
    };

    // Handler for ride description change
    const handleRideDescriptionChange = (event) => {
        setRideDescription(event.target.value);
    };

    return (
        <Box sx={{ padding: 4 }}>
            {/* Back Arrow */}
            <IconButton
                sx={{ position: 'relative', top: 0, mb: 2 }}
                onClick={() => window.history.back()}
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography variant="h4" sx={{ mb: 1 }}>
                Review Your Ride
            </Typography>

            {/* Introductory Text */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Almost done! Just review your ride information, choose the maximum number of passengers you can take, and add a short description about your ride.
            </Typography>

            {/* Divider Line */}
            <Divider sx={{ mb: 3 }} />

            {/* Segment Cards */}
            {segments.map((segment, index) => (
                <Box key={index} sx={{ padding: 2, mb: 3, borderBottom: '1px solid #e0e0e0' }}>
                    <SegmentCard segment={segment} index={index + 1} mode={"detailed"} />
                </Box>
            ))}

            {/* Max Number of Passengers with Slider */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Select the maximum number of passengers:</Typography>

                {/* Wrapper Box with margin/padding for centering */}
                <Box sx={{ px: 2, mt: 2 }}> {/* Apply padding on the container instead of the Slider */}
                    <Slider
                        value={maxPassengers}
                        onChange={(e, newValue) => handlePassengerSelection(newValue)}
                        step={1}
                        min={1}
                        max={3}
                        marks={[{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }]}
                        valueLabelDisplay="auto"
                        color="primary"
                    />
                </Box>
            </Box>

            {/* Ride Description */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Ride Description:</Typography>
                <Box
                    sx={{
                        border: '1px solid #e0e0e0', // Outline with the same color as dividers
                        borderRadius: 0, // Subtle border radius
                        mt: 2, // Space between the title and the outlined box
                    }}
                >
                    <TextField
                        multiline
                        fullWidth
                        rows={4}
                        value={rideDescription}
                        onChange={handleRideDescriptionChange}
                        inputProps={{ maxLength: 200 }}
                        placeholder="Describe your ride..."
                        variant="outlined"
                        sx={{ p: 0 }} // Padding within the TextField for better spacing inside the outlined box
                    />
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {200 - rideDescription.length} characters remaining
                </Typography>
            </Box>

            {/* Terms and Conditions */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Checkbox
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    color="primary"
                />
                <Typography variant="body2" color="text.secondary">
                    I agree to the{' '}
                    <Link component="button" onClick={handleTermsOpen} underline="always" color="primary">
                        Terms and Conditions
                    </Link>.
                </Typography>
            </Box>

            {/* Next Step Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <NextStepButton
                    label="Confirm and Submit"
                    redirectPath="/post-ride-confirmation"
                    locations={[]}
                    formattedDate={formattedDate}
                    numberOfPassengers={maxPassengers}
                    rideDescription={rideDescription}
                    segments={segments}
                    roundTrip={roundTrip}
                />
            </Box>

            {/* Modal for Terms and Conditions */}
            <Modal open={termsOpen} onClose={handleTermsClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: 400, bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 24 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Terms and Conditions</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Here is where the terms and conditions text will go. This is just a placeholder for now. The content will be more detailed and provide all the necessary information.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleTermsClose} variant="contained" color="primary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default PostRideReview;

import React, { useEffect, useState } from 'react';
import { Card, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Slide, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import apiConfig from "../../../config/apiConfig";


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ProfileBioCard = ({ bio, textIfUnfilled, profile_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bioText, setBioText] = useState(bio || ''); // Text in the modal input

    // Sync initial bio to modal input and displayed bio when `bio` prop changes
    useEffect(() => {
        if (bio) {
            setBioText(bio);
            console.log("Initial bio set:", bio);
        }
    }, [bio]);

    const handleOpenModal = () => {
        console.log("Opening modal");
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        console.log("Closing modal");
        setIsModalOpen(false);
    };

    const handleSaveBio = async () => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/update-user-bio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    profile_id: profile_id,
                    bio_text: bioText,
                }),
            });

            if (response.ok) {
                console.log("Bio saved successfully:", bioText);
                handleCloseModal(); // Close the modal after saving
            } else {
                console.error('Failed to update bio');
            }
        } catch (error) {
            console.error('Error updating bio:', error);
        }
    };

    return (
        <>
            {/* Display Bio Card with Edit Icon */}
            <Card
                onClick={handleOpenModal}
                sx={{
                    cursor: 'pointer',
                    mb: 2,
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center', // Center items vertically within the Card
                    p: 1,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, ml: 2 }}>
                    <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </Box>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}>
                    Update your bio
                </Typography>
            </Card>

            {/* Modal for Editing Bio */}
            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogTitle sx={{ textAlign: 'center' }}>Edit Bio</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: 750,
                        width: '100%',
                        mx: 'auto',
                    }}
                >
                    <Box sx={{ mt: 3, width: '100%' }}>
                        <TextField
                            label="Tell us about yourself..."
                            multiline
                            fullWidth
                            rows={6}
                            value={bioText}
                            onChange={(e) => setBioText(e.target.value)}
                            placeholder="Tell us about yourself..."
                            variant="outlined"
                            sx={{
                                backgroundColor: 'secondary.main',
                                borderRadius: 2, // Rounded background for the input box
                            }}
                            InputLabelProps={{
                                sx: {
                                    bgcolor: 'white', // White background for label
                                    px: 1, // Horizontal padding for legibility
                                    borderRadius: '16px', // Rounded corners for pill shape
                                    transform: 'translate(10px, -6px) scale(0.85)', // Adjust position when floating
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'flex-end',
                        maxWidth: 750,
                        width: '100%',
                        mx: 'auto',
                        pr: 2,
                    }}
                >
                    <Button onClick={handleSaveBio} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            borderColor: 'secondary.main',
                            color: 'primary.main',
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfileBioCard;

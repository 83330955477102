import React from 'react';
import { Card, CardContent, Typography, Box, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const ProfileEmailVerificationCard = ({ email, verification_status }) => {
    return (
        <Tooltip
            title={
                verification_status
                    ? "Your email has been verified"
                    : "Your email has not yet been verified"
            }
        >
            <Card
                sx={{
                    mb: 2,
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                    color: 'text.primary',
                    p: 0, // Set padding to zero to avoid additional space
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 0 !important', // Explicit padding control, including zeroing out any CardContent default
                    }}
                >
                    <Box sx={{ color: 'primary.main', mr: 2, ml: 3 }}>
                        {verification_status ? (
                            <CheckCircleIcon fontSize="small" />
                        ) : (
                            <HourglassEmptyIcon fontSize="small" />
                        )}
                    </Box>

                    <Typography variant="body1" sx={{ margin: 0, color: 'primary.main' }}>
                        {email}
                    </Typography>
                </CardContent>
            </Card>
        </Tooltip>
    );
};

export default ProfileEmailVerificationCard;

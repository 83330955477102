import React, { useState } from 'react';
import { Box, Typography, TextField, Grid } from '@mui/material'; // Import MUI components

function AddMessage({ driverFirstName }) {
    const [message, setMessage] = useState('');
    const maxCharacters = 500;

    const handleMessageChange = (e) => {
        if (e.target.value.length <= maxCharacters) {
            setMessage(e.target.value);
        }
    };

    return (
        <Box sx={{ width: '100%', marginTop: 5, padding: 0 }}>
            {/* Title */}
            <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                Write a message to {driverFirstName}
            </Typography>

            {/* Message Input Field */}
            <TextField
                multiline
                rows={5} // Default number of rows
                fullWidth
                variant="outlined"
                placeholder="Hi! Can I get more information on..."
                value={message}
                onChange={handleMessageChange}
                inputProps={{ maxLength: maxCharacters }}
                sx={{
                    marginBottom: 2,
                    backgroundColor: 'background.default',
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        '& fieldset': {
                            borderColor: 'text.secondary',
                        },
                        '&:hover fieldset': {
                            borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                        },
                    },
                }}
            />

            {/* Character Count */}
            <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="text.secondary">
                    {message.length}/{maxCharacters} characters
                </Typography>
            </Grid>
        </Box>
    );
}

export default AddMessage;

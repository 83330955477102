import React, { useEffect, useState } from 'react';
import { useProfile } from '../../context/ProfileContext'; // Import the custom hook
import RidesList from '../Rides/Components/RidesList'; // Import RidesList component
import { Box, Grid, Typography } from '@mui/material';
import apiConfig from "../../config/apiConfig"; // Import MUI components

function ViewMyRidesPassenger() {
    // Access profile_id from context
    const { profile_id, loadingProfile } = useProfile();

    // State variables for storing API results
    const [driverRides, setDriverRides] = useState([]);
    const [passengerRides, setPassengerRides] = useState([]);
    const [loading, setLoading] = useState(true);

    // Function to call getMyRides API for drivers
    const fetchDriverRides = async () => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-my-rides`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    type: 'Driver',
                    profile_id: profile_id,
                }),
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log('Driver Rides:', data.rides);
            setDriverRides(data.rides);
        } catch (error) {
            console.error('Error fetching driver rides:', error);
        }
    };

    // Function to call getMyRides API for passengers
    const fetchPassengerRides = async () => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-my-rides`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    type: 'Passenger',
                    profile_id: profile_id,
                }),
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log('Passenger Rides:', data.rides);
            setPassengerRides(data.rides);
        } catch (error) {
            console.error('Error fetching passenger rides:', error);
        }
    };

    // Use effect to call both APIs once profile_id is available
    useEffect(() => {
        if (!loadingProfile && profile_id) {
            // Call both APIs
            Promise.all([fetchDriverRides(), fetchPassengerRides()])
                .finally(() => setLoading(false));
        }
    }, [loadingProfile, profile_id]);

    if (loading || loadingProfile) {
        return (
            <Typography variant="h6" align="center">
                Loading...
            </Typography>
        );
    }

    return (
        <Box>
            {/* Title with 8px left margin */}
            <Typography variant="h4" sx={{ marginLeft: 1, marginTop: 2, marginBottom: 1 }}>
                Upcoming Rides
            </Typography>

            {/* Passenger Rides List */}
            <Box sx={{ marginTop: 1, marginLeft: 2, marginRight: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {passengerRides.length === 0 ? (
                            <Typography variant="h6" align="center">
                                No Passenger Rides Found
                            </Typography>
                        ) : (
                            <RidesList segmentMatches={passengerRides} mode={"ViewMyRides"} />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ViewMyRidesPassenger;

import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Modal, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import apiConfig from "../../../config/apiConfig";

function CancelRideButton({ mode, passengerDetails, segmentGroup }) {
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        console.log("Mode:", mode);
        console.log("Passenger Details:", passengerDetails);
        console.log("Segment Group:", segmentGroup);
    }, [mode, passengerDetails, segmentGroup]);

    const handleCancellation = async () => {
        try {
            console.log("mode :", mode);
            if (mode === "ViewMyRidesDriver") {
                // Use the new API to cancel the ride for drivers
                await axios.post(`${apiConfig.baseUrl}/api/rides/update-ride-status`, {
                    ride_id: segmentGroup.ride_id,
                    status: "Cancelled"
                });
            } else if (mode === "ViewMyRides") {
                // Use the passenger segment cancellation API
                console.log("pax detials. seg id. :", passengerDetails[0].segment_ids);
                console.log("pax detials. prof id. :", passengerDetails[0].profile_id);

                await axios.post(`${apiConfig.baseUrl}/api/rides/update-passenger-segment-status`, {
                    segmentIds: passengerDetails[0].segment_ids,
                    profile_id: passengerDetails[0].profile_id,
                    status: "Cancelled"
                });
            }
            handleCloseModal(); // Close modal after successful cancellation
            // Optionally, add further logic here, such as updating the state to reflect the change in UI
        } catch (error) {
            console.error("Error cancelling ride:", error);
        }
    };


    if ((mode !== "ViewMyRides" && mode !== "ViewMyRidesDriver")) {
        return null; // Only render for specified modes
    }

    let bodyText;

    if (mode === "ViewMyRides") {
        bodyText = "You may cancel your ride without any charge up to 24 hours before departure. However, for confirmed bookings, cancellations made within 24 hours of the departure time will incur a fee equal to 100% of the booking price.";
    } else if (mode === "ViewMyRidesDriver") {
        bodyText = "To maintain a reliable experience for passengers, please avoid cancelling rides within 5 days of the scheduled departure. Passengers are notified if drivers frequently cancel, so consistency helps build trust.";
    }

    // Display different messages based on status
    if (segmentGroup.passenger_status === "Cancelled" && mode === "ViewMyRides") {
        return (
            <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 3 }}>
                Your request has been cancelled.
            </Typography>
        );
    }
    else if (segmentGroup.status === "cancelled") {
        return (
            <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 3 }}>
                This ride has been cancelled.
            </Typography>
        );
    } else if (segmentGroup.passenger_status === "Declined" && mode === "ViewMyRides") {
        return (
            <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 3 }}>
                Your request was not accepted by the driver.
            </Typography>
        );
    }

    return (
        <>
            {/* Main Cancel Ride Button */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Center the component
                    backgroundColor: 'background.paper',
                    border: 1,
                    borderColor: 'secondary.main',
                    borderRadius: 2,
                    padding: 2,
                    maxWidth: 600,
                    marginLeft: 2,
                    marginRight: 2,
                    textAlign: 'center',
                }}
            >
                <Typography variant="body1" color="text.primary" sx={{ marginRight: 2 }}>
                    Need to cancel your ride?
                </Typography>
                <Button
                    variant="contained"
                    onClick={handleOpenModal}
                    sx={{
                        backgroundColor: 'warning.dark',
                        color: 'warning.contrastText',
                        textTransform: 'none',
                    }}
                >
                    Cancel
                </Button>
            </Box>

            {/* Full-Screen Modal */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                }}
            >
                <Paper
                    sx={{
                        position: 'relative',
                        padding: 4,
                        width: '100%',
                        maxWidth: 500,
                        marginX: 2,
                        textAlign: 'center',
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginBottom: 3 }}>
                        Are you sure you want to cancel?
                    </Typography>
                    <Typography variant="body2" align="center" sx={{ marginTop: 2, color: 'text.secondary' }}>
                        {bodyText}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, marginTop: 4 }}>
                        {/* Go Back Button */}
                        <Button
                            variant="outlined"
                            onClick={handleCloseModal}
                            sx={{
                                flex: 2, // 2/3 of space
                                borderColor: 'secondary.main',
                                color: 'text.primary',
                                textTransform: 'none',
                            }}
                        >
                            Go Back
                        </Button>
                        {/* Cancel Ride Button */}
                        <Button
                            variant="contained"
                            onClick={() => {
                                // Handle cancellation logic here
                                handleCloseModal();
                                handleCancellation();
                            }}
                            sx={{
                                flex: 1, // 1/3 of space
                                backgroundColor: 'warning.dark',
                                color: 'warning.contrastText',
                                textTransform: 'none',
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Paper>
            </Modal>
        </>
    );
}

export default CancelRideButton;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShortTextInput from '../../components/simple_form_components/ShortTextInput';  // Import the ShortTextInput component
import BirthdayInput from '../../components/simple_form_components/BirthdayInput';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './Registration.css';
import apiConfig from "../../config/apiConfig";

function Registration() {
    const { user } = useLocation().state || {};  // Extract user from the location state
    console.log("Received user data: ", user);

    const navigate = useNavigate(); // Initialize the navigate function

    const [step, setStep] = useState(0);  // Step for cycling through the form inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [email, setEmail] = useState('');

    const handleBirthDateChange = (newDate) => {
        // Pad the month and day with leading zero if needed
        const formattedMonth = newDate.month.padStart(2, '0');
        const formattedDay = newDate.day.padStart(2, '0');

        // Combine year, month, and day into a valid date string
        const formattedBirthDate = `${newDate.year}-${formattedMonth}-${formattedDay}`;

        // Update birthDate with the formatted string
        setBirthDate(formattedBirthDate);
    };

    // Pre-fill input fields with data if available
    useEffect(() => {
        if (user?.given_name) {
            setFirstName(user.given_name);
        }
        if (user?.family_name) {
            setLastName(user.family_name);
        }
        if (user?.email) {
            setEmail(user.email);
        }
    }, [user]);

    const createNewUser = async () => {
        try {
            const auth0_user_id = user.sub;

            const requestBody = {
                auth0_user_id,
                firstName,
                lastName,
                email,
                DoB: birthDate
            }

            // Check if the user exists in your database
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/create-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                console.log("New user created successfully.");
                // Redirect to /profile after user creation
                navigate('/profile');
            } else {
                throw new Error('Failed to create new user.');
            }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    // Function to handle submission of each step
    const handleSubmit = () => {
        if (step === 0) {
            console.log("First Name:", firstName);
            setStep(1);  // Move to the next step (Last Name input)
        } else if (step === 1) {
            console.log("Last Name:", lastName);
            setStep(2);
        } else if (step === 2){
            createNewUser().then(r => {});
            //navigate('/profile');
        }
    };

    return (
        <div className="registration-container">
            <h1 className="registration-title">Tell Us About Yourself</h1>

            <div className="input-box"> {/* Box wrapper with rounded corners */}
                {/* Display Input Based on Step */}
                {step === 0 && (
                    <ShortTextInput
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        buttonLabel="Continue"
                        onSubmit={handleSubmit}
                    />
                )}
                {step === 1 && (
                    <ShortTextInput
                        label="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        buttonLabel="Continue"
                        onSubmit={handleSubmit}
                    />
                )}
                {step === 2 && (
                    <BirthdayInput
                        label="Date of Birth"
                        onChange={handleBirthDateChange}
                        buttonLabel="Continue"
                        onSubmit={handleSubmit}
                    />
                )}
            </div>
        </div>
    );
}

export default Registration;



/*
// User doesn't exist, create a new user in the database
await fetch(`${apiConfig.baseUrl}/api/profile/create-user`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
    },
    body: JSON.stringify({
        auth0_user_id: auth0UserId,
    })
});
console.log("New user created in the database.");

 */
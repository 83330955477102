import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SearchBar from '../../components/ride_search_bar/SearchBar';
import SearchSummary from '../../components/ride_search_bar/SearchSummary';  // Import SearchSummary
import RidesList from './Components/RidesList';
import {Container, Box, Grid, Divider, Typography} from '@mui/material'; // Import MUI components
import { formatDate } from '../../utils/dateUtils';
import { Helmet } from 'react-helmet';
import apiConfig from "../../config/apiConfig";

function Rides() {
    // Use state to store the values
    const [initialDepartureAddress, setInitialDepartureAddress] = useState('');
    const [initialDepartureCity, setInitialDepartureCity] = useState('');
    const [initialArrivalAddress, setInitialArrivalAddress] = useState('');
    const [initialArrivalCity, setInitialArrivalCity] = useState('');
    const [initialTravelDate, setInitialTravelDate] = useState('');
    const [initialRoundTrip, setInitialRoundTrip] = useState(false); // Use boolean instead of string
    const [segmentMatches, setSegmentMatches] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state
    const [showSearchBar, setShowSearchBar] = useState(false);  // State to control visibility of SearchBar

    const location = useLocation();  // This gives you access to the current URL and search params

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const departureAddress = params.get('departure_location');
        const departureCity = params.get('departure_city');
        const arrivalAddress = params.get('arrival_location');
        const arrivalCity = params.get('arrival_city');
        const travelDate = params.get('date');
        const roundTrip = params.get('round_trip') === 'true'; // Convert to boolean

        // Set the state variables with the parsed values
        setInitialDepartureAddress(departureAddress);
        setInitialDepartureCity(departureCity);
        setInitialArrivalAddress(arrivalAddress);
        setInitialArrivalCity(arrivalCity);
        setInitialTravelDate(travelDate);
        setInitialRoundTrip(roundTrip);

        // Introduce a delay before fetching segments
        const delay = setTimeout(() => {
            fetchSegmentsNearLocation(departureAddress, arrivalAddress, travelDate, roundTrip)
                .then(matches => {
                    if (Array.isArray(matches?.relevantSegments)) {
                        setSegmentMatches(matches.relevantSegments);
                    } else {
                        setSegmentMatches([]);
                        setShowSearchBar(true);
                    }
                })
                .catch(error => {
                    console.error("Error with the API call:", error);
                    setSegmentMatches([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 1); //get rid of delay

        // Cleanup the timeout if the component unmounts or effect re-runs
        return () => clearTimeout(delay);
    }, [location.search]);

    const handleSummaryClick = () => {
        setShowSearchBar(true);  // Show SearchBar when SearchSummary is clicked
    };

    return (
        <>
            <Helmet>
                <meta property="og:title" content={"Title test (dynamic)"}/>
                <meta property="og:description" content={"Description Test (dynamic)"}/>
                <meta property="og:image" content={"/content/Logo_mobile.svg"}/>
            </Helmet>
            <Container maxWidth="lg" sx={{padding: 1}}> {/* Use Container for central alignment */}
                <Box sx={{marginBottom: 0}}> {/* Box for margin/padding control */}
                    <Grid container spacing={0} justifyContent="center">
                        {showSearchBar ? (
                            <SearchBar
                                initialDepartureAddress={initialDepartureAddress}
                                initialDepartureCity={initialDepartureCity}
                                initialArrivalAddress={initialArrivalAddress}
                                initialArrivalCity={initialArrivalCity}
                                initialTravelDate={initialTravelDate}
                                initialRoundTrip={initialRoundTrip}
                                enableHistory={true}
                            />
                        ) : (
                            initialDepartureCity && initialArrivalCity && initialTravelDate && (
                                <SearchSummary
                                    initialDepartureCity={initialDepartureCity}
                                    initialArrivalCity={initialArrivalCity}
                                    initialTravelDate={initialTravelDate}
                                    onClick={handleSummaryClick}
                                />
                            )
                        )}

                    </Grid>
                </Box>

                <Box sx={{ padding: 2 }}>
                    <Typography variant="h6" color="primary">
                        {formatDate(initialTravelDate)} {/* Use the formatDate function */}
                    </Typography>
                    <Typography variant="body1">
                        {initialRoundTrip ? "Round trip only" : "One way"} {/* boolean check */}
                    </Typography>
                    <Typography variant="body1">
                        {segmentMatches.length} {segmentMatches.length === 1 ? "ride found" : "rides found"}
                    </Typography>
                    <Divider sx={{ marginTop: 2 }} />
                </Box>

                <Box sx={{ marginTop: 1, marginLeft: 2, marginRight: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {loading ? (
                                <Typography variant="h6" align="center">
                                    Loading...
                                </Typography>
                            ) : (
                                <RidesList
                                    segmentMatches={segmentMatches}
                                    mode={"Ride Search"}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default Rides;

// geocodes any given address
async function geocode(address) {
    try {
        // Make the API request to the backend /api/geo/get-geocode
        const response = await fetch(`${apiConfig.baseUrl}/api/geo/get-geocode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
            },
            body: JSON.stringify({ placeName: address })  // Send the address as 'placeName'
        });

        if (!response.ok) {
            throw new Error('Error fetching geocode');
        }

        const data = await response.json(); // Parse the JSON response
        if (data) {
            return data; // Assuming data contains coordinates in {latitude, longitude}
        } else {
        }
    } catch (error) {
        console.error("Error during geocoding: ", error);
    }
}

// Calls the API, uses geocode() to provide the coordinates, sets radius and date, and the API will return matching segments
async function fetchSegmentsNearLocation(departureAddress, arrivalAddress, date, roundTrip) {
    try {
        const radius = 25000; // Radius in meters
        const departureCoordinates = await geocode(departureAddress); // Get departure coordinates
        const departureLongitude = departureCoordinates.longitude;
        const departureLatitude = departureCoordinates.latitude;
        const arrivalCoordinates = await geocode(arrivalAddress); // Get arrival coordinates
        const arrivalLongitude = arrivalCoordinates.longitude;
        const arrivalLatitude = arrivalCoordinates.latitude;

        console.log("rides.jsx - roundtrip :", roundTrip);

        // Construct the query parameters for the API call to fetch segments
        const response = await fetch(`${apiConfig.baseUrl}/api/rides/nearby-segments?departureLatitude=${departureLatitude}&departureLongitude=${departureLongitude}&arrivalLatitude=${arrivalLatitude}&arrivalLongitude=${arrivalLongitude}&date=${encodeURIComponent(date)}&radius=${radius}&round_trip=${roundTrip ? 'true' : 'false'}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089', // Replace with secure API key management
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json(); // Return the array of relevant segments from the API
    } catch (error) {
        console.error('Error fetching segments:', error);
        return null; // Or you could return an empty array [] if preferred
    }
}

export const formatDate = (dateString) => {
    if (!dateString) return "No date provided";  // Fallback if no date is provided

    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const date = new Date(dateString);

    return date.toLocaleDateString('en-US', options);  // Format the date as Monday, Nov 11
};

export const isPast = (dateString, timeString) => {
    // Combine the date and time strings into a single Date object
    const targetDateTime = new Date(`${dateString}T${timeString}`);

    // Get the current date and time
    const currentDateTime = new Date();

    // Check if the target date and time is in the past
    return targetDateTime < currentDateTime;
};

import React, { useState } from 'react';
import {
    Card,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Slide,
    } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import apiConfig from "../../../config/apiConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfilePreferencesCard = ({ label, profile_id, preference_conversation, preference_music, onSave }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [conversationPreference, setConversationPreference] = useState(preference_conversation || 'sometimes');
    const [musicPreference, setMusicPreference] = useState(preference_music || 'sometimes');

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSavePreferences = async () => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/update-user-preferences`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    profile_id: profile_id,
                    conversationPreference,
                    musicPreference,
                }),
            });

            if (response.ok) {
                onSave({
                    preference_conversation: conversationPreference,
                    preference_music: musicPreference,
                });
                handleCloseModal();
            } else {
                console.error('Failed to update preferences');
            }
        } catch (error) {
            console.error('Error updating preferences:', error);
        }
    };

    return (
        <>
            {/* Card to open preferences modal */}
            <Card
                onClick={handleOpenModal}
                sx={{
                    cursor: 'pointer',
                    mb: 2,
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, ml: 2 }}>
                    <EditIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </Box>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }}>
                    {label}
                </Typography>
            </Card>

            {/* Modal for editing preferences */}
            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogTitle sx={{textAlign: "center"}}>Edit Ride Preferences</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: 750,
                        width: '100%',
                        mx: 'auto',
                    }}
                >
                    <Box sx={{ mt: 3, width: '100%' }}>
                        {/* Conversation Preference */}
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel
                                sx={{
                                    backgroundColor: 'white',
                                    px: 1,
                                    borderRadius: '16px',
                                }}
                            >
                                Do you prefer to chat?
                            </InputLabel>
                            <Select
                                value={conversationPreference}
                                onChange={(e) => setConversationPreference(e.target.value)}
                                label="Conversation Preference"
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    borderRadius: 2, // Rounded background
                                }}
                            >
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                                <MenuItem value="sometimes">Sometimes</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Music Preference */}
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{
                                    backgroundColor: 'white',
                                    px: 1,
                                    borderRadius: '16px',
                                }}
                            >
                                Do you prefer music?
                            </InputLabel>
                            <Select
                                value={musicPreference}
                                onChange={(e) => setMusicPreference(e.target.value)}
                                label="Music Preference"
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    borderRadius: 2, // Rounded background
                                }}
                            >
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                                <MenuItem value="sometimes">Sometimes</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'flex-end',
                        maxWidth: 750,
                        width: '100%',
                        mx: 'auto',
                        pr: 2,
                    }}
                >
                    <Button onClick={handleSavePreferences} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            borderColor: 'secondary.main',
                            color: 'primary.main',
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfilePreferencesCard;

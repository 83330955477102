import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SearchSummary = ({ initialDepartureCity, initialArrivalCity, initialTravelDate, onClick }) => {
    // Try to convert the travel date string to a Date object
    const parsedDate = initialTravelDate ? new Date(initialTravelDate) : null;

    // Check if the parsed date is valid
    const isValidDate = parsedDate instanceof Date && !isNaN(parsedDate);

    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                border: '1px solid #A8D5BA',  // Outline color from theme
                boxShadow: 'none',
                width: 'calc(100% - 32px)',  // 4 units of spacing on each side
                margin: '16px auto',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#f0f0f0',
                    borderColor: '#8BB398',
                },
            }}
        >
            {/* Search Icon */}
            <SearchIcon sx={{ marginRight: '8px', color: '#8BB398' }} />

            {/* Text: {departureCity} ---> {arrivalCity} on {date} */}
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: '#3E5C76', fontWeight: 500 }}>
                {initialDepartureCity || 'Departure'}
                <ArrowForwardIcon sx={{ margin: '0 8px', fontSize: '16px', color: '#3E5C76' }} />
                {initialArrivalCity || 'Arrival'}
                &nbsp;on&nbsp;
                {isValidDate ? parsedDate.toLocaleDateString() : 'Date not set'}
            </Typography>
        </Box>
    );
};

export default SearchSummary;

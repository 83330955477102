// src/App.js
import React from 'react';
import Layout from './wrappers/Layout';  // Import the Layout component
import AppRoutes from './AppRoutes';
import { ProfileProvider } from './context/ProfileContext';  // Import ProfileProvider
import {ThemeProvider} from "@mui/material/styles"; // Import your custom theme
import theme from './theme';
import {useLocation} from "react-router-dom"; // Import your custom theme

function App() {
    const location = useLocation();
    // Define which paths should not show the header/footer
    const noHeaderFooterPaths = ['/coming-soon', '/'];
    const showHeaderFooter = !noHeaderFooterPaths.includes(location.pathname);


    return (
        <ThemeProvider theme={theme}>
            <ProfileProvider>
                <Layout showHeader={showHeaderFooter} showFooter={showHeaderFooter}>
                    <div className="App">
                        <AppRoutes />
                    </div>
                </Layout>
            </ProfileProvider>
        </ThemeProvider>
    );
}

export default App;

// src/pages/Home.js
import React from 'react';
import SearchBar from '../../components/ride_search_bar/SearchBar';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NatureIcon from '@mui/icons-material/Nature';

function Home() {
    const theme = useTheme(); // Access theme for secondary color

    return (
        <Box sx={{ position: 'relative', zIndex: 1 }}>
            {/* Background Box - Separate container for background */}
            <Box
                sx={{
                    backgroundImage: 'url("/content/home/home_top_section.svg")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    minHeight: '100vh', // Full viewport height, even on larger screens
                    width: '100vw', // Full width of the viewport
                    height: '100%', // Ensure background covers entire page height
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    opacity: 0.4, // Apply opacity to the background
                    zIndex: -1,
                }}
            />

            {/* Foreground Content */}
            <Container maxWidth="md"> {/* Center content and limit width */}
                <Box sx={{ padding: '20px 20px 0' }}>
                    {/* Title Text */}
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.text.primary,
                            marginBottom: '20px',
                            textAlign: 'center',
                        }}
                    >
                        The cheapest way to get across the Northeast
                    </Typography>

                    {/* SearchBar Component */}
                    <SearchBar enableHistory={true} />

                    {/* Add margin to SearchBar for spacing */}
                    <Box sx={{ marginBottom: { xs: '40px', md: '80px' } }}> {/* Add spacing below SearchBar */}</Box>

                    {/* New Content Section */}
                    <Box
                        sx={{
                            marginTop: { xs: '100px', md: '40px' }, // 100px for mobile, 40px for larger screens
                            maxWidth: { xs: '375px', md: '100%' }, // Max width of 375px for screens below 960px, full width for larger screens
                            margin: { xs: '0 auto', md: '0' }, // Center content horizontally on smaller screens
                        }}
                    >
                        <Grid container spacing={4}>
                            {/* Group 1 */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <LockIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>Secure</Typography>
                                <Typography variant="body1">
                                    All drivers are verified with their driver’s license, so you can feel confident about who you’re sharing a ride with.
                                </Typography>
                            </Grid>

                            {/* Group 2 */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <AttachMoneyIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>Affordable</Typography>
                                <Typography variant="body1">
                                    Travel for less—ride-sharing costs less than driving solo, flying, or even taking the bus or train.
                                </Typography>
                            </Grid>

                            {/* Group 3 */}
                            <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                <NatureIcon sx={{ fontSize: '40px', color: theme.palette.primary.main }} />
                                <Typography variant="h6" sx={{ marginTop: '10px' }}>Sustainable</Typography>
                                <Typography variant="body1">
                                    Help reduce emissions by filling empty seats and sharing rides, making every trip more eco-friendly.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* New Section with 2/3 and 1/3 Layout for Desktop */}
                    <Grid container spacing={4} sx={{ marginTop: '40px' }}>
                        {/* Parent Box to link the heights of Driver and Student sections */}
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on mobile, horizontally on desktop
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/* 2/3 Section: Driver Info */}
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.secondary.main, // Light blue background from theme
                                        borderRadius: theme.shape.borderRadius, // Rounded corners from theme
                                        padding: '20px',
                                        textAlign: 'center',
                                        flex: '2', // Flex ratio to take 2/3 of the space
                                    }}
                                >
                                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                                        {/* Custom Graphic */}
                                        <Grid item xs={12}>
                                            <Box
                                                component="img"
                                                src="/content/custom_graphics/ride_with_friends.png"
                                                alt="Custom Graphic"
                                                sx={{
                                                    maxWidth: { xs: '70%', md: '70%' }, // Adjust for mobile and desktop
                                                    height: 'auto',
                                                }}
                                            />
                                        </Grid>

                                        {/* Text Content */}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                                                Driving somewhere?
                                            </Typography>
                                            <Typography variant="body1">
                                                Driving is way too expensive these days. Bring costs down, and maybe make a few new friends along the way.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {/* Post a Ride Button */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            marginTop: '28px',
                                            borderRadius: '50px', // Pill-shaped button
                                            padding: '10px 40px',
                                            fontSize: '16px',
                                            textTransform: 'none',
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                            zIndex: 1,
                                        }}
                                        onClick={() => window.location.href = '/post-ride'} // Placeholder link
                                    >
                                        Post a ride
                                    </Button>
                                </Box>

                                {/* 1/3 Section: Student Friendly */}
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: theme.shape.borderRadius,
                                        padding: '20px',
                                        textAlign: 'center', // Ensure content is centered
                                        flex: '1', // Flex ratio to take 1/3 of the space
                                        marginLeft: { md: '20px' }, // Add margin between the two boxes for desktop
                                        marginTop: { xs: '20px', md: '0' }, // Add margin on top for mobile view
                                    }}
                                >
                                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                        Student friendly
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginBottom: '22px' }}>
                                        Learn how we make coming home for winter break easier than ever.
                                    </Typography>

                                    {/* Image Above Button */}
                                    <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                                        <Box
                                            component="img"
                                            src="/content/custom_graphics/student_group.png"
                                            alt="Placeholder Graphic"
                                            sx={{
                                                maxWidth: { xs: '50%', md: '100%' }, // 50% for small screens, 100% for medium and larger
                                                height: 'auto',
                                            }}
                                        />
                                    </Box>

                                    {/* Centered Button Below the Image */}
                                    <Box sx={{ textAlign: 'center' }}> {/* Center the button */}
                                        <Button
                                            color="primary"
                                            sx={{
                                                marginTop: '30px', // Add spacing above the button
                                                borderRadius: '50px',
                                                padding: '10px 30px',
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                            onClick={() => window.location.href = '/student-discounts'} // Placeholder link
                                        >
                                            Learn more
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Full-Width Ski/Snowboard Section */}
                        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.quartiary.main, // Light color for new section
                                    borderRadius: theme.shape.borderRadius, // Rounded corners from theme
                                    padding: '20px',
                                }}
                            >
                                <Grid container spacing={4} alignItems="center" justifyContent="center">
                                    {/* Left side: Image */}
                                    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                                        <Box
                                            component="img"
                                            src="/content/custom_graphics/snowboarder_group.png"
                                            alt="Snowboarder"
                                            sx={{
                                                maxWidth: { xs: '50%', md: '100%' }, // 70% width for mobile, 100% for desktop
                                                height: 'auto',
                                            }}
                                        />
                                    </Grid>

                                    {/* Right side: Text content with button */}
                                    <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                                            Ski or Snowboard?
                                        </Typography>
                                        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
                                            We love skiing just as much as you. Let us help you get to the mountain for less.
                                        </Typography>

                                        {/* "To the mountains!" Button */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                borderRadius: '50px', // Pill-shaped button
                                                padding: '10px 30px',
                                                fontSize: '16px',
                                                textTransform: 'none',
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                            onClick={() => window.location.href = '/mountains'} // Placeholder link
                                        >
                                            To the mountains!
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Home;

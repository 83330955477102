import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Avatar,
    MenuItem,
    useMediaQuery,
    Typography,
    Drawer, Button, Modal, Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LoginIcon from '@mui/icons-material/Login'; // Login icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../context/ProfileContext'; // Import useProfile for context
import { signOut } from '../supabase auth/loginFunctions'; // Import Supabase auth functions
import { useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from '@mui/icons-material/Group';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import apiConfig from "../config/apiConfig";

const Header = () => {
    const { user, isAuthenticated, loadingProfile } = useProfile(); // Use ProfileContext
    const [profileData, setProfileData] = useState(null); // State to store profile data
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Use media query to detect small screens
    const [openManageRidesModal, setOpenManageRidesModal] = useState(false);

    useEffect(() => {
        if (!loadingProfile && isAuthenticated && user?.id) {
            const fetchUserProfile = async (supabase_user_id) => {
                try {
                    const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                        },
                        body: JSON.stringify({ UID: supabase_user_id }),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setProfileData(data); // Set the profile data, including profile picture if available
                    } else {
                        throw new Error('Failed to fetch profile data.');
                    }
                } catch (error) {
                    console.error('Error fetching profile:', error);
                }
            };

            fetchUserProfile(user.id);
        }
    }, [loadingProfile, isAuthenticated, user]);

    const navigateToLogin = () => {
        navigate('/login'); // Redirects the user to the /login page
    };

    const handleMenuOpen = (event) => {
        setOpen(true);
    };

    const handleMenuClose = () => {
        setOpen(false);
    };

    const handleManageRidesClick = () => {
        setOpenManageRidesModal(true);
    };

    const closeManageRidesModal = () => {
        setOpenManageRidesModal(false);
    };

    const handleLogout = () => {
        signOut();
        navigate('/home');
        handleMenuClose();
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                zIndex: 10,
                '&:hover': {
                    backgroundColor: theme.palette.background.paper, // Prevent color change on hover
                },
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left Side: Profile and Dropdown */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isAuthenticated ? (
                        <Box
                            onClick={handleMenuOpen} // Trigger drawer on click
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: 'background.paper',
                                border: 2,
                                borderColor: 'primary.main',
                                borderRadius: '22px', // Make it pill-shaped
                                paddingX: 0, // Horizontal padding inside the pill boundary
                                paddingY: 0, // Vertical padding for alignment
                                marginY: .5,
                                cursor: 'pointer', // Indicate clickable area
                                transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                                '&:hover': {
                                    backgroundColor: 'secondary.main', // Hover effect on entire pill box
                                },
                            }}
                        >
                            <IconButton color="inherit" aria-label="profile" sx={{ padding: 0 }}> {/* Profile Avatar */}
                                <Avatar
                                    alt={user?.name}
                                    src={profileData?.profile_picture || '/content/profile/default_profile_picture.svg'}
                                    sx={{ width: 40, height: 40 }}
                                />
                            </IconButton>

                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{
                                    padding: 0, // Remove hover effect from IconButton
                                    '&:hover': { backgroundColor: 'transparent' }, // Ensure no hover effect on the icon itself
                                }}
                            >
                                <MenuIcon sx={{ fontSize: { xs: '20px', md: '30px' }, marginLeft: 2, marginRight: 1 }} />
                            </IconButton>
                        </Box>
                    ) : (
                        <IconButton color="inherit" onClick={() => navigateToLogin()} aria-label="login">
                            <Typography variant="h7" sx={{ marginRight: '8px', display: 'inline', color: 'inherit' }}>
                                Log In
                            </Typography>
                            <LoginIcon />
                        </IconButton>
                    )}

                    <Drawer
                        anchor="left"
                        open={open}
                        onClose={handleMenuClose}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: { xs: '100%', md: '750px' },
                                height: '100vh', // Full page height for the drawer
                                backgroundColor: theme.palette.background.paper,
                                boxSizing: 'border-box',
                                padding: 2,
                                overflowY: 'hidden', // Hide scrollbar
                            },
                        }}
                    >
                        {/* Close Button in the Drawer */}
                        <IconButton
                            onClick={handleMenuClose}
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 1301,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 3, mt: 2 }}>
                            {/* First Line: User's Profile Picture and Name */}
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                <Avatar
                                    src={profileData?.profile_picture || '/content/profile/default_profile_picture.svg'}
                                    alt={profileData?.first_name}
                                    sx={{ width: 60, height: 60, marginRight: 2 }}
                                />
                                <Typography variant="h4">{profileData?.first_name}</Typography>
                            </Box>

                            {/* Line 1: Two Buttons for Find a Ride and Post a Ride */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, marginBottom: 1.5 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { navigate('/home'); handleMenuClose(); }}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <SearchIcon sx={{ fontSize: 30, marginBottom: 0.5 }} />
                                    <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>Find a Ride</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => { navigate('/post-ride'); handleMenuClose(); }}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <AddIcon sx={{ fontSize: 30, marginBottom: 0.5 }} />
                                    <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>Post a Ride</Typography>
                                </Button>
                            </Box>

                            {/* Line 2: Three Buttons for Manage Profile, Inbox, and Manage Rides */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, marginBottom: 2 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { navigate('/profile'); handleMenuClose(); }}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <AccountCircleIcon sx={{ fontSize: 30, marginBottom: 0.5 }} />
                                    <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>Manage Profile</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => { navigate('/inbox'); handleMenuClose(); }}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <MailIcon sx={{ fontSize: 30, marginBottom: 0.5 }} />
                                    <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>Inbox</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={handleManageRidesClick}
                                    sx={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingY: 2,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                        },
                                    }}
                                >
                                    <DriveEtaIcon sx={{ fontSize: 30, marginBottom: 0.5 }} />
                                    <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>Manage Rides</Typography>
                                </Button>
                            </Box>

                            {/* Additional Links */}
                            <MenuItem onClick={() => { navigate('/payment-preferences'); handleMenuClose(); }}>Payment Preferences</MenuItem>
                            <MenuItem onClick={() => { navigate('/payouts'); handleMenuClose(); }}>Payouts</MenuItem>
                            <MenuItem onClick={() => { navigate('/about-us'); handleMenuClose(); }}>About Us</MenuItem>
                            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                        </Box>
                    </Drawer>
                </Box>

                {/* Manage Rides Modal */}
                <Modal
                    open={openManageRidesModal}
                    onClose={closeManageRidesModal}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2, // Padding for the modal on all sides
                    }}
                >
                    <Paper
                        sx={{
                            p: 4,
                            maxWidth: 600,
                            width: '100%',
                            m: 2, // Margin of 2 units on the left and right
                        }}
                    >
                        <Typography variant="h5" align="center" gutterBottom>
                            Manage Rides
                        </Typography>

                        {/* Button for Passenger Portal */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 2,
                                mb: 2,
                                borderRadius: 2,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                },
                            }}
                            onClick={() => {
                                navigate('/my-rides-passenger');
                                closeManageRidesModal();
                                handleMenuClose(); // Closes the drawer as well
                            }}
                        >
                            <GroupIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                            <Box>
                                <Typography variant="h6" color="text.primary">
                                    Passenger Portal
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Click here to manage your rides as a passenger
                                </Typography>
                            </Box>
                        </Box>

                        {/* Button for Driver Portal */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 2,
                                borderRadius: 2,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                },
                            }}
                            onClick={() => {
                                navigate('/my-rides-driver');
                                closeManageRidesModal();
                                handleMenuClose(); // Closes the drawer as well
                            }}
                        >
                            <DirectionsCarIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                            <Box>
                                <Typography variant="h6" color="text.primary">
                                    Driver Portal
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Click here to manage your drives
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Modal>

                {/* Center: Logo */}
                <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <img
                        src={isMobile ? "/content/Logo_mobile.svg" : "/content/Logo.svg"}
                        alt="Logo"
                        style={{ height: isMobile ? '40px' : '50px', cursor: 'pointer' }}
                        onClick={() => navigate('/home')}
                    />
                </Box>

                {/* Right Side: Icons */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton edge="start" color="inherit" aria-label="search" onClick={() => navigate('/home')}>
                        <SearchIcon sx={{ fontSize: { xs: '20px', md: '30px' } }} />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

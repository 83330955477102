import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RideItemDriverInfo from './RideItemDriverInfo';
import {Box, Paper, Typography} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Green checkmark icon
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Hourglass icon
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {useTheme} from "@mui/material/styles"; // Icon for completed ride
import { formatDate } from '../../../utils/dateUtils';
import { isPast } from '../../../utils/dateUtils';
import CloseIcon from '@mui/icons-material/Close';
import apiConfig from "../../../config/apiConfig";

function RideItem({ segmentGroup, mode }) {
    const navigate = useNavigate();
    const [isFull, setIsFull] = useState(false);
    const [statusDisplay, setStatusDisplay] = useState(''); // New state for statusDisplay
    const [passengerDetails, setPassengerDetails] = useState([]); // Only for mode === "ViewMyRidesDriver"
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

    // Determine if the ride is in the past
    const isCompleted = isPast(segmentGroup.date, segmentGroup.departure_info.time);

    const rideCancelled = segmentGroup.status === "cancelled";

    // Determine the status display icon
    let statusIcon = null;
    if (mode === "ViewMyRides" || mode === "ViewMyRidesDriver") {
        if (statusDisplay === "Confirmed") {
            statusIcon = <CheckCircleIcon sx={{ color: 'primary.main', fontSize: 20 }} />;
        } else if (statusDisplay === "Pending") {
            statusIcon = <HourglassEmptyIcon sx={{ color: 'primary.main', fontSize: 20 }} />;
        } else if (statusDisplay === "Declined" || statusDisplay === "Cancelled") {
            statusIcon = <CloseIcon sx={{ color: 'primary.main', fontSize: 20 }} />;
        } else if (statusDisplay === "Attention" ){
            statusIcon = <PriorityHighIcon sx={{ color: 'primary.main', fontSize: 15 }} />;
        }
    }

    useEffect(() => {
        // Set the statusDisplay based on mode (ViewMyRidesPassenger) and status conditions
        console.log("is ride cancelled? ", rideCancelled);
        console.log("RideItem.jsx - segment group:", segmentGroup);
        if (mode === "ViewMyRides") {
            if (isCompleted) {
                setStatusDisplay("Completed");
            } else if (segmentGroup.passenger_status === "Confirmed") {
                setStatusDisplay("Confirmed");
            } else if (segmentGroup.passenger_status === "Declined") {
                setStatusDisplay("Declined");
            } else if (segmentGroup.passenger_status === "Cancelled") {
                setStatusDisplay("Cancelled");
            } else if (segmentGroup.passenger_status === "Pending Approval"){
                setStatusDisplay("Pending");
            }
        } else if (mode === "ViewMyRidesDriver") {
            if (isCompleted) {
                setStatusDisplay("Completed");
            } else if (rideCancelled){
                setStatusDisplay("Cancelled");
            } else if (pendingApprovalCount > 0) {
                setStatusDisplay("Attention");
            }
        }
    }, [mode, isCompleted, segmentGroup.passenger_status, pendingApprovalCount]);

    useEffect(() => {
        setIsFull(segmentGroup.available_seats === 0);
    }, [segmentGroup.available_seats]);

    const handleRideClick = () => {
        if (!isFull && !isCompleted) {
            if (mode === "ViewMyRides" || mode === "ViewMyRidesDriver") {
                // Navigate with state for specific modes
                navigate(`/details`, { state: { stateMode: mode, stateSegmentGroup: segmentGroup, statePassengerDetails: passengerDetails, statePendingApprovalCount: pendingApprovalCount } });
            } else {
                // Navigate with segments and mode in the URL for other cases
                const segmentsParam = encodeURIComponent(JSON.stringify(segmentGroup.segment_ids)); // Encode array as a URL-safe string
                navigate(`/details?carpool=${segmentsParam}&mode=${encodeURIComponent(mode)}`);
            }
        }
    };

    const fetchPassengerDetails = async (segmentIds) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-profile-by-segments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ segmentIds }),
            });

            const data = await response.json();

            if (data && data.data) {
                // Extract the array of passenger details from the response
                const passengerDetails = data.data;

                // Initialize counters
                let pendingCount = 0;
                let acceptedCount = 0;

                // Iterate through the passenger details array
                passengerDetails.forEach(passenger => {
                    if (passenger.status === "Pending Approval") {
                        pendingCount++;
                    } else if (passenger.status === "Accepted") {
                        acceptedCount++;
                    }
                });

                // Update counts
                setPendingApprovalCount(pendingCount);

                return passengerDetails; // Return the passenger details for further use if needed
            } else {
                console.error('No passenger data found in the response');
                return [];
            }
        } catch (error) {
            console.error('Error fetching passenger details:', error);
            return [];
        }
    };

    // Trigger the API call when segmentGroup.segments changes and mode is "ViewMyRidesDriver"
    useEffect(() => {
        if (segmentGroup.segment_ids) {
            fetchPassengerDetails(segmentGroup.segment_ids)
                .then((data) => {
                    console.log('Fetched passenger details:', data); // Log the returned data
                    setPassengerDetails(data); // Store the passenger details in the state
                })
                .catch((error) => {
                    console.error('Error during fetching passenger details:', error);
                });
        }
    }, [segmentGroup.segment_ids]);

    function formatTimeTo12Hour(timeString) {
        const [hour, minute] = timeString.split(':');
        let hourInt = parseInt(hour, 10);
        const ampm = hourInt >= 12 ? 'PM' : 'AM';
        hourInt = hourInt % 12 || 12;
        return `${hourInt}:${minute} ${ampm}`;
    }

    return (
        <Paper
            elevation={0}  // Flat by default
            onClick={isCompleted ? undefined : handleRideClick}  // Disable click when completed
            sx={{
                backgroundColor: isCompleted ? 'grey.300' : (isFull ? 'grey.300' : 'secondary.light'),
                borderRadius: '32px',
                margin: '0 auto',
                marginBottom: 2,
                width: '100%',
                cursor: isFull || isCompleted ? 'not-allowed' : 'pointer',  // Disable pointer when completed
                '&:hover': {
                    // Only apply hover effects when not full or completed
                    boxShadow: !isFull && !isCompleted ? '0 8px 16px rgba(0, 0, 0, 0.2)' : 'none',
                    transform: !isFull && !isCompleted ? 'translateY(-2px)' : 'none',
                },
                transition: 'box-shadow 0.3s ease, transform 0.3s ease, border 0.3s ease',
            }}
        >
            {(mode === "ViewMyRides" || mode === "ViewMyRidesDriver") && (
                <Box
                    sx={{
                        borderColor: 'primary.main',
                        width: 'calc(100% - 16px)', // Adjust width to avoid overflow
                        padding: 1,
                        display: 'flex', // Flexbox layout
                        flexDirection: 'column', // Items stacked vertically
                        alignItems: 'center', // Center horizontally within the box
                        justifyContent: 'center',
                        borderTopLeftRadius: '32px',
                        borderTopRightRadius: '32px',
                        marginX: 'auto', // Center the box within the parent
                    }}
                >
                    {/* Top Section: Date on the left, Status on the right */}
                    <Box
                        sx={{
                            display: 'flex', // Flexbox for horizontal alignment
                            justifyContent: 'space-between', // Space between date and status
                            alignItems: 'center', // Center vertically
                            width: '100%', // Ensure it takes the full width
                            marginTop: 1,
                        }}
                    >
                        {/* Left: Date (formatted using formatDate) */}
                        <Typography variant="h7" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary', marginLeft: 1.5 }}>
                            {formatDate(segmentGroup.date)}
                        </Typography>

                        {/* Right: Status */}
                        {!isCompleted && statusDisplay && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center', // Center icon and text vertically
                                    backgroundColor:
                                        (statusDisplay === "Declined" || statusDisplay === "Cancelled")
                                            ? 'warning.dark'
                                            : 'secondary.main', // Conditionally set color
                                    borderRadius: 50, // Pill shape
                                    paddingX: 1,
                                    paddingY: 0.5,
                                    marginRight: 2,
                                }}
                            >
                                {/* Status Icon */}
                                <Box sx={{ marginRight: 0.5, display: 'flex', alignItems: 'center' }}>
                                    {statusIcon}
                                </Box>

                                {/* Status Text */}
                                <Typography variant="body2" sx={{ color: 'secondary.contrastText', fontWeight: 'bold' }}>
                                    {statusDisplay}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {/* Top Section: Departure, Arrival Info, Status Icons */}
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {/* Left side: Departure and Arrival Info */}
                    <Box sx={{ textAlign: 'left', width: '80%' }}>
                        {/* Line 1: Departure Info with Location Pin Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                            <LocationOnIcon sx={{ marginRight: 1, fontSize: '20px', color: isCompleted ? 'text.disabled' : 'text.primary' }} />
                            <Typography variant="h6" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary' }}>
                                {segmentGroup.departure_info.city}{' '}
                                <Typography variant="body2" component="span" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary' }}>
                                    {formatTimeTo12Hour(segmentGroup.departure_info.time)}
                                </Typography>
                            </Typography>
                        </Box>

                        {/* Line 2: Arrival Info with Checkered Flag Icon */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                            <FlagIcon sx={{ marginRight: 1, fontSize: '20px', color: isCompleted ? 'text.disabled' : 'text.primary' }} />
                            <Typography variant="h6" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary' }}>
                                {segmentGroup.arrival_info.city}{' '}
                                <Typography variant="body2" component="span" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary' }}>
                                    {formatTimeTo12Hour(segmentGroup.arrival_info.time)}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>

                    {/* Right side: Price or Full display for other modes */}
                    {mode !== "ViewMyRides" && mode !== "ViewMyRidesDriver" && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end', // Align to right
                                alignItems: 'flex-start',  // Align to top
                            }}
                        >
                            <Typography variant="h5" sx={{ color: isCompleted ? 'text.disabled' : 'text.primary' }}>
                                {isFull ? 'Full' : `$${segmentGroup.price}`}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {mode !== "ViewMyRidesDriver" && (
                <Box>
                    <RideItemDriverInfo driver_id={segmentGroup.driver_id} />
                </Box>
            )}
        </Paper>
    );
}

export default RideItem;

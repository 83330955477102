import React from 'react';
import { Box, Grid, Typography, IconButton, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();

    const handleShare = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        alert('Link copied to clipboard!');
    };

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.secondary.main,
                maxWidth: '620px', // Restrict width to 750px
                width: 'calc(100% - 120px)', // Take full width of container but max 750px
                margin: '0 auto', // Center the footer horizontally
                padding: '40px 20px', // Add padding of 20px on all sides
                borderRadius: '16px 16px 0 0', // Rounded corners of 16px
                boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow
                textAlign: 'center', // Center content within the footer
                position: 'relative', // Ensure it stays in normal flow
                zIndex: 1,
            }}
        >
            <Grid container spacing={4} justifyContent="center">
                {/* Major Links */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                        Explore
                    </Typography>
                    <Link href="/home" underline="none" sx={{ color: theme.palette.text.primary, display: 'block', marginBottom: '10px' }}>
                        Home
                    </Link>
                    <Link href="/post-ride" underline="none" sx={{ color: theme.palette.text.primary, display: 'block' }}>
                        Post a Ride
                    </Link>
                </Grid>

                {/* Company Information */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                        Company
                    </Typography>
                    <Link href="/about-us" underline="none" sx={{ color: theme.palette.text.primary, display: 'block', marginBottom: '10px' }}>
                        About Us
                    </Link>
                    <Link href="/contact" underline="none" sx={{ color: theme.palette.text.primary, display: 'block', marginBottom: '10px' }}>
                        Contact Us
                    </Link>
                    <Link href="/help" underline="none" sx={{ color: theme.palette.text.primary, display: 'block' }}>
                        How it Works
                    </Link>
                </Grid>

                {/* Legal Links */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                        Legal
                    </Typography>
                    <Link href="/privacy-policy" underline="none" sx={{ color: theme.palette.text.primary, display: 'block', marginBottom: '10px' }}>
                        Privacy Policy
                    </Link>
                    <Link href="/terms" underline="none" sx={{ color: theme.palette.text.primary, display: 'block' }}>
                        Terms of Service
                    </Link>
                </Grid>
            </Grid>

            {/* Social Media and Share Buttons */}
            <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
                <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                    Follow Us
                </Typography>

                {/* Social Media Icons */}
                <IconButton href="https://www.facebook.com" target="_blank" sx={{ color: theme.palette.primary.main }}>
                    <FacebookIcon />
                </IconButton>
                <IconButton href="https://www.instagram.com" target="_blank" sx={{ color: theme.palette.primary.main }}>
                    <InstagramIcon />
                </IconButton>
                <IconButton href="https://www.linkedin.com" target="_blank" sx={{ color: theme.palette.primary.main }}>
                    <LinkedInIcon />
                </IconButton>

                {/* Share Button */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                        Share this page
                    </Typography>
                    <IconButton onClick={handleShare} sx={{ color: theme.palette.primary.main }}>
                        <ShareIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;

import React from 'react';
import { IconButton } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Importing MUI swap icon

function SwapArrow({ departureAddress, arrivalAddress, setDepartureAddress, setArrivalAddress }) {
    const handleSwap = () => {
        // Swap the values of departure and arrival cities
        setDepartureAddress(arrivalAddress);
        setArrivalAddress(departureAddress);
    };

    return (
        <IconButton
            onClick={handleSwap}
            color="primary" // You can change this to match your theme (primary/secondary)
        >
            <SwapHorizIcon /> {/* Using MUI swap icon */}
        </IconButton>
    );
}

export default SwapArrow;

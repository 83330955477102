import React, { useEffect, useState } from 'react';
import {Box, Card, CardContent, Typography, CircularProgress, Avatar} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import CheckIcon from "@mui/icons-material/Check";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import apiConfig from "../../../config/apiConfig";

const PassengerDetailsCards = ({ segmentDetails }) => {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Step 2: Initialize the navigate hook

    // Function to fetch user profiles
    const fetchUserProfiles = async (profilesArray) => {
        try {
            // Check if profilesArray is indeed an array
            if (!Array.isArray(profilesArray) || profilesArray.length === 0) {
                setError('No valid segment details available.');
                setLoading(false);
                return;
            }

            const fetchedProfiles = await Promise.all(
                profilesArray.map(async (profile) => {
                    const response = await axios.post(`${apiConfig.baseUrl}/api/profile/get-user-profile`, { profile_id: profile.profile_id });
                    return {
                        ...profile,
                        user: response.data.user, // Store user information along with profile info
                    };
                })
            );
            setProfiles(fetchedProfiles);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user profiles:', error);
            setError('Failed to fetch user profiles.');
            setLoading(false);
        }
    };

    // Call API to fetch user profiles when the component mounts
    useEffect(() => {
        console.log("PassengerDetailsCards.jsx - segmentDetails: ", segmentDetails);
        if (segmentDetails && Array.isArray(segmentDetails)) {
            fetchUserProfiles(segmentDetails);
        } else {
            setLoading(false);
        }
    }, [segmentDetails]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {profiles.length === 0 ? (
                <Typography variant="h6" align="center">
                    No Passenger Details Found
                </Typography>
            ) : (
                profiles
                    .filter((profile) => profile.status !== 'Declined')
                    .map((profile, index) => (
                        <Card
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: 2,
                                minWidth: 275,
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                },
                                flexDirection: { xs: 'column', sm: 'row' }, // Adjust layout for small screens
                            }}
                            onClick={() => navigate('/view-profile', {
                                state: {
                                    profile: profile, // This will pass all profile data for the individual passenger
                                    mode: "Passenger View" // Set the mode to "Passenger View"
                                }
                            })}
                        >
                            {/* Avatar and Profile Info */}
                            <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: '70%' } }}>
                                <Avatar
                                    src={profile.profile_picture}
                                    alt={`${profile.first_name}`}
                                    sx={{ width: 64, height: 64, marginRight: 2 }}
                                />

                                <CardContent sx={{ flex: 1, padding: 0 }}>
                                    {/* First name with status display */}
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" sx={{ marginRight: 1 }}>
                                            {profile.first_name}
                                        </Typography>

                                        {/* Status: Accepted or Pending Approval */}
                                        {profile.status === 'Accepted' ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'success.main' }}>
                                                <CheckIcon sx={{ fontSize: 18, marginRight: 0.5 }} />
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    Accepted
                                                </Typography>
                                            </Box>
                                        ) : profile.status === 'Pending Approval' ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'secondary.main' }}>
                                                <HourglassEmptyIcon sx={{ fontSize: 18, marginRight: 0.5 }} />
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    Pending Approval
                                                </Typography>
                                            </Box>
                                        ) : null}
                                    </Box>

                                    {/* Verified Student Status */}
                                    {profile.verified_student && (
                                        <Typography
                                            variant="body2"
                                            sx={{ fontWeight: 'bold', color: 'success.main', marginTop: 0 }}
                                        >
                                            Verified Student
                                        </Typography>
                                    )}

                                    {/* Departure and Arrival City */}
                                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 0 }}>
                                        {profile.departure_info.city} to {profile.arrival_info.city}
                                    </Typography>

                                    {/* Round-trip or One-way */}
                                    <Typography variant="body2" color="text.secondary" sx={{ marginTop: 0 }}>
                                        {profile.return_departure_info && profile.return_arrival_info ? 'Round-trip' : 'One-way'}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    ))
            )}
        </Box>
    );
};

export default PassengerDetailsCards;

import React from 'react';
import {Button, Typography, IconButton, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Importing the close (X) icon
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Importing the map pin icon

function SearchInputButton({ address, placeholder, onClick, onDelete, noTopRoundedCorners, noRoundedCorners }) {
    // Function to truncate address if it exceeds 35 characters
    const getDisplayAddress = (address) => {
        return address && address.length > 35 ? `${address.slice(0, 35)}...` : address;
    };

    return (
        <Button
            fullWidth
            variant="contained"
            onClick={onClick}
            sx={{
                borderRadius: noRoundedCorners ? '0' : noTopRoundedCorners ? '20px 20px 0 0' : '20px', // Logic for both options
                padding: '16px',
                textAlign: 'left',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)', // Darken on hover
                },
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* Replacing div with Box for MUI consistency */}
                <LocationOnIcon sx={{ marginRight: '18px', color: 'primary' }} />
                <Typography variant="body1" sx={{ color: address ? 'text.primary' : 'text.secondary' }}>
                    {address ? getDisplayAddress(address) : placeholder}
                </Typography>
            </Box>
            {address && (
                <IconButton
                    aria-label="clear address"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the onClick that opens the modal
                        onDelete(); // Call the delete function
                    }}
                    size="small"
                    sx={{
                        marginLeft: '8px',
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        </Button>
    );
}

export default SearchInputButton;

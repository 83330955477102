import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { Check } from '@mui/icons-material'; // Import a simple checkmark icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function ConfirmationSuccess() {
    const navigate = useNavigate();

    // Handlers for the buttons
    const handleReturnHome = () => {
        navigate('/home');
    };

    const handleViewRides = () => {
        navigate('/profile');
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 4 }}>
            {/* Success Header with Checkmark Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Check sx={{ color: 'primary', fontSize: '80px', marginRight: 1 }} />
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                    Your Transaction Was Successful!
                </Typography>
            </Box>

            {/* Success Message Body */}
            <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center', marginBottom: 4 }}>
                You will only be charged once the driver accepts your request. After you are accepted, you will receive a confirmation email. You can also check the status of your ride confirmation on your profile.
            </Typography>

            {/* Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                {/* Return to Home Button */}
                <Button
                    variant="contained"
                    onClick={handleReturnHome}
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                        paddingX: 4,
                        paddingY: 1.5,
                        borderRadius: '50px', // Pill-shaped button
                    }}
                >
                    Return to Home
                </Button>

                {/* View Your Rides Button */}
                <Button
                    variant="outlined"
                    onClick={handleViewRides}
                    sx={{
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        paddingX: 4,
                        paddingY: 1.5,
                        borderRadius: '50px', // Pill-shaped button
                        '&:hover': {
                            backgroundColor: 'primary.light',
                            borderColor: 'primary.dark',
                        },
                    }}
                >
                    View Your Rides
                </Button>
            </Box>
        </Container>
    );
}

export default ConfirmationSuccess;

import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, Divider, Tabs, Tab, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Ensure your Supabase client is configured in this file

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0); // 0 for login, 1 for sign-up
    const navigate = useNavigate(); // Initialize navigate hook

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSignIn = async () => {
        setLoading(true);
        try {
            const { error } = await supabase.auth.signInWithPassword({ email, password });
            if (error) {
                alert('Error: ' + error.message);
            } else {
                alert('Login successful!');
                navigate('/home'); // Redirect to /home after login
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSignUp = async () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        setLoading(true);
        try {
            const { error } = await supabase.auth.signUp({ email, password });
            if (error) {
                alert('Error: ' + error.message);
            } else {
                alert('Sign-up successful! Check your email for confirmation.');
                navigate('/home'); // Redirect to /home after sign-up
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const { error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
            if (error) {
                alert('Error: ' + error.message);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 8 }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {activeTab === 0 ? 'Log In' : 'Sign Up'}
                </Typography>
            </Box>
            <Tabs value={activeTab} onChange={handleChangeTab} centered>
                <Tab label="Log In" />
                <Tab label="Sign Up" />
            </Tabs>
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowPassword} edge="end">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {activeTab === 1 && (
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        margin="normal"
                        required
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowConfirmPassword} edge="end">
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={activeTab === 0 ? handleSignIn : handleSignUp}
                        disabled={loading}
                    >
                        {loading ? (activeTab === 0 ? 'Logging in...' : 'Signing up...') : (activeTab === 0 ? 'Log In' : 'Sign Up')}
                    </Button>
                </Box>
                {activeTab === 0 && (
                    <>
                        <Divider sx={{ my: 2 }}>or</Divider>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="tertiary"
                                onClick={handleGoogleSignIn}
                                startIcon={<img src="/content/custom_icons/google-icon.png" alt="Google icon" style={{ width: 24 }} />}
                            >
                                Sign In with Google
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default LoginForm;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationDetailsCard from './ride_detail_components/LocationDetailsCard'; // Import the LocationDetailsCard component
import DriverDetailsCard from './ride_detail_components/DriverDetailsCard'; // Import the DriverDetailsCard component
import PassengerDetailsCards from '../ViewMyRides/Components/PassengerDetailsCards';
import ContinueReservationBar from './ride_detail_components/ContinueReservationBar';
import {Box, Typography, Paper, Divider, Button} from '@mui/material'; // Import MUI components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {formatDate} from "../../utils/dateUtils";
import InfoIcon from "@mui/icons-material/Info";
import CancelRideButton from './ride_detail_components/CancelRideButton';
import ErrorReturnHome from '../../components/Error/ErrorReturnHome';
import apiConfig from "../../config/apiConfig";

function RideDetails() {
    const [driverDetails, setDriverDetails] = useState(null);
    const [segmentGroup, setSegmentGroup] = useState(null);
    const [passengerDetails, setPassengerDetails] = useState(null);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    // Extract mode from URL or state
    const queryParams = new URLSearchParams(location.search);
    const rawSegmentIds = queryParams.get('carpool');
    const urlMode = queryParams.get('mode');
    const { stateMode, stateSegmentGroup, statePassengerDetails, statePendingApprovalCount } = location.state || {};

    const mode = urlMode || stateMode;
    let segmentIds;

    try {
        // Parse the segmentIds as a JSON array if possible
        segmentIds = JSON.parse(decodeURIComponent(rawSegmentIds));
        if (!Array.isArray(segmentIds)) {
            throw new Error('Parsed segmentIds is not an array');
        }
    } catch (error) {
        console.error('Failed to parse segmentIds from URL:', error);
        segmentIds = null; // Set to null or handle the fallback if needed
    }

    // Function to fetch segment details from the API
    const fetchSegmentInfo = async (segmentIds) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-segment-info-by-segments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ segmentIds }),
            });
            const data = await response.json();
            if (data.success) {
                return data.data; // Adjust as needed based on the response structure
            } else {
                throw new Error(data.message || 'Failed to fetch segment info');
            }
        } catch (error) {
            console.error('Error fetching segment info:', error);
            setError('Failed to fetch segment info');
            return null;
        }
    };

    // Function to fetch the driver information
    const fetchDriverDetails = async (driver_id) => {
        try {
            const profileResponse = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ profile_id: driver_id }),
            });
            return await profileResponse.json();
        } catch (error) {
            console.error('Error fetching driver details:', error);
            return null;
        }
    };

    useEffect(() => {
        console.log("RideDetails.jsx - URL Params:", { segmentIds, mode });
        console.log("RideDetails.jsx - stateSegmentGroup:", stateSegmentGroup);

        const fetchData = async () => {
            try {
                setLoading(true);

                if (mode === "Ride Search") {
                    if (segmentIds) {
                        // Fetch segment information
                        const segmentData = await fetchSegmentInfo(segmentIds);
                        if (segmentData) {
                            setSegmentGroup(segmentData);
                            console.log("set w/ segmentData :", segmentData);
                            // Fetch driver details using the driver_id from segment data
                            const driverDetails = await fetchDriverDetails(segmentData.driver_id);
                            if (driverDetails) {
                                setDriverDetails(driverDetails);
                            }
                        } else {
                            throw new Error('Segment data not found');
                        }
                    } else {
                        throw new Error('Invalid segment IDs');
                    }
                } else if (stateSegmentGroup) {
                    // Use the state provided if not in "Ride Search" mode
                    setSegmentGroup(stateSegmentGroup);
                    setPassengerDetails(statePassengerDetails);
                    setPendingApprovalCount(statePendingApprovalCount);

                    // Fetch driver details
                    const driverDetails = await fetchDriverDetails(stateSegmentGroup.driver_id);
                    if (driverDetails) {
                        setDriverDetails(driverDetails);
                    } else {
                        throw new Error('Driver details not found');
                    }
                } else {
                    throw new Error('Segment group not found in state');
                }
            } catch (err) {
                setError('This ride cannot be found');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();

        console.log("RideDetails.jsx - driverDetails (end): ", driverDetails);
        console.log("RideDetails.jsx - segmentGroup (end): ", segmentGroup);

    }, []); // Empty dependency array ensures this only runs once

    if (error) {
        return <ErrorReturnHome message="This ride cannot be found" />;
    }

    if (loading) return <div>Loading...</div>;

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 0, // Remove padding from the box
            maxWidth: 800,
            margin: '0 auto',
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
            backgroundColor: 'transparent' // Ensure the background is transparent
        }}>
            <Paper sx={{
                width: '100%',
                maxWidth: '750px', // Ensure a max width
                padding: 2,
                backgroundColor: 'transparent', // Remove the background color
                borderRadius: 0, // Remove the border radius for seamlessness
                boxShadow: 'none', // Remove the shadow so it blends with the page
                overflow: 'visible', // Allow overflow if needed
                boxSizing: 'border-box', // Ensure box-sizing for proper width calculation
            }}>
                <ArrowBackIcon
                    onClick={() => navigate(-1)}
                    sx={{ cursor: 'pointer', color: 'primary.main', fontSize: '24px' }} // Add cursor pointer for clickability
                    aria-label="go-back"
                />

                {/* Info Box if Pending Approval Count > 0 */}
                {mode === "ViewMyRidesDriver" && pendingApprovalCount > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            border: '1px solid',
                            borderColor: 'primary.main',
                            padding: 1,
                            borderRadius: 1,
                            marginBottom: 2, // Add spacing below the info box
                            marginTop: 2,
                        }}
                    >
                        <InfoIcon sx={{ color: 'primary.main', marginRight: 1 }} />
                        <Typography
                            variant="body2"
                            sx={{ color: 'text.primary', textAlign: 'left' }}
                        >
                            Please scroll down to approve passengers
                        </Typography>
                    </Box>
                )}

                <Box sx={{ marginBottom: 3 }}>
                    {/* Line 1: Departure City, Icon, Arrival City */}
                    <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', color: 'text.primary' }}>
                        {segmentGroup.departure_info.city} {/* Departure City */}
                        {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) ? (
                            <SwapHorizIcon sx={{ marginX: 1, fontSize: '24px', color: 'primary.main' }} />
                        ) : (
                            <ArrowForwardIcon sx={{ marginX: 1, fontSize: '24px', color: 'primary.main' }} />
                        )}
                        {segmentGroup.arrival_info.city} {/* Arrival City */}
                    </Typography>

                    {/* Line 2: Date */}
                    <Typography variant="h7" sx={{ color: 'text.primary', marginBottom: 3 }}>
                        {formatDate(segmentGroup.date)} {/* Use the util function to format the date */}
                    </Typography>
                </Box>

                {segmentGroup && (
                    <Box sx={{ marginBottom: '0px' }}> {/* Added bottom margin for spacing */}
                        {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                {/* Divider line on the left of the text */}
                                <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main', marginRight: 1 }} />

                                {/* The text itself */}
                                <Typography variant="h7" sx={{ marginRight: 1 }}>
                                    Outbound Journey
                                </Typography>

                                {/* Divider line on the right of the text */}
                                <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main' }} />
                            </Box>
                        )}

                        {/* Departure Location */}
                        <LocationDetailsCard
                            initial_text="Departing at"
                            address={segmentGroup.departure_info.address}
                            city={segmentGroup.departure_info.city}
                            time={segmentGroup.departure_info.time}
                        />
                        <LocationDetailsCard
                            initial_text="Arriving at"
                            address={segmentGroup.arrival_info.address}
                            city={segmentGroup.arrival_info.city}
                            time={segmentGroup.arrival_info.time}
                        />

                        {/* Conditional rendering of return LocationDetailsCard if round_trip is true */}
                        {(segmentGroup.round_trip === "true" || segmentGroup.round_trip === true) && (
                            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
                                {/* "Return Info" text centered on top of the divider */}
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2}}>
                                    {/* Divider line on the left of the text */}
                                    <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main', marginRight: 1 }} />

                                    {/* The text itself */}
                                    <Typography variant="h7" sx={{ marginRight: 1 }}>
                                        Return Journey
                                    </Typography>

                                    {/* Divider line on the right of the text */}
                                    <Divider sx={{ flexGrow: 1, backgroundColor: 'primary.main' }} />
                                </Box>

                                {/* Return Location Details */}
                                <LocationDetailsCard
                                    initial_text="Return at"
                                    address={segmentGroup.return_departure_info.address}
                                    city={segmentGroup.return_departure_info.city}
                                    time={segmentGroup.return_departure_info.time}
                                />
                                <LocationDetailsCard
                                    initial_text="Arriving at"
                                    address={segmentGroup.return_arrival_info.address}
                                    city={segmentGroup.return_arrival_info.city}
                                    time={segmentGroup.return_arrival_info.time}
                                />
                            </Box>
                        )}

                        <Divider sx={{ marginY: 3 }} />

                        <Box>
                            {/* Driver Details - Only display if mode is NOT "ViewMyRidesDriver" */}
                            {mode !== "ViewMyRidesDriver" && driverDetails && (
                                <DriverDetailsCard driver={driverDetails} description={segmentGroup.description} />
                            )}

                            {/* Display passenger details if mode is "ViewMyRidesDriver" and passengers exist */}
                            {mode === "ViewMyRidesDriver" && passengerDetails.length > 0 && (
                                <PassengerDetailsCards segmentDetails={passengerDetails} />
                            )}

                            {/* Display a message if no passenger details are available */}
                            {mode === "ViewMyRidesDriver" && passengerDetails.length === 0 && (
                                <Typography variant="h6" align="center">
                                    No Passenger Requests Yet
                                </Typography>
                            )}
                        </Box>

                        {mode !== "ViewMyRides" && mode !== "ViewMyRidesDriver" && (
                            <ContinueReservationBar
                                segmentGroup={segmentGroup}
                                driverDetails={driverDetails}
                            />
                        )}

                        {(mode === "ViewMyRides" || mode === "ViewMyRidesDriver") && (
                            <Box sx={{ marginTop: 6 }}>
                                <CancelRideButton
                                    mode={mode}
                                    passengerDetails={passengerDetails}
                                    segmentGroup={segmentGroup}
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Paper>
        </Box>
    );
}

export default RideDetails;

// src/AppRoutes.js
import React from 'react';
import Home from './pages/Home/Home';
import ComingSoon from './pages/Coming Soon/ComingSoon';
import LoginForm from './supabase auth/LoginForm';
import About from './pages/About/About';
import Rides from './pages/Rides/Rides';
import RideDetails from "./pages/RideDetails/RideDetails";
import Profile from "./pages/Profile/Profile";
import Registration from "./pages/Registration/Registration";
import Review from "./pages/Review/Review";
import ConfirmationSuccess from "./pages/Confirmation/ConfirmationSuccess";
import Inbox from "./pages/Inbox/Inbox";
import Messages from "./pages/Messages/Messages";
import ViewProfile from "./pages/ViewProfile/ViewProfile";
import PostRide from "./pages/PostRideGroup/0. PostRideHome/PostRide";
import PostRideAddDetails from "./pages/PostRideGroup/0. PostRideHome/PostRide";
import PostRideAddStops from "./pages/PostRideGroup/2. PostRideAddStops/PostRideAddStops";
import PostRideSetTimes from "./pages/PostRideGroup/3. PostRideSetTimes/PostRideSetTimes";
import PostRideSetPrices from "./pages/PostRideGroup/4. PostRideSetPrices/PostRideSetPrices";
import PostRideReview from "./pages/PostRideGroup/5. PostRideReview/PostRideReview";
import PostRideConfirmation from "./pages/PostRideGroup/6. PostRideConfirmation/PostRideConfirmation";
import ViewMyRidesPassenger from "./pages/ViewMyRides/ViewMyRidesPassenger";
import ViewMyRidesDriver from "./pages/ViewMyRides/ViewMyRidesDriver";

import {Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/non_visible_components/ScrollToTop";

function AppRoutes() {
    return (
        <>
            <ScrollToTop /> {/* This ensures scroll-to-top on every route change */}
            <Routes>
                    <Route path="/" element={<ComingSoon />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/rides" element={<Rides />} />
                    <Route path="/details" element={<RideDetails />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/review" element={<Review />} />
                    <Route path="/success" element={<ConfirmationSuccess />} />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/view-profile" element={<ViewProfile />} />
                    <Route path="/post-ride" element={<PostRide />} />
                    <Route path="/post-ride-add-details" element={<PostRideAddDetails />} />
                    <Route path="/post-ride-add-stops" element={<PostRideAddStops />} />
                    <Route path="/post-ride-set-times" element={<PostRideSetTimes />} />
                    <Route path="/post-ride-set-prices" element={<PostRideSetPrices />} />
                    <Route path="/post-ride-review" element={<PostRideReview />} />
                    <Route path="/post-ride-confirmation" element={<PostRideConfirmation />} />
                    <Route path="/my-rides-passenger" element={<ViewMyRidesPassenger />} />
                    <Route path="/my-rides-driver" element={<ViewMyRidesDriver />} />
            </Routes>
        </>
    );
}

export default AppRoutes;

import React from 'react';
import Header from './Header';
import Footer from './Footer'; // Import the Footer component
import { Box } from '@mui/material';

function Layout({ children, showHeader = true, showFooter = true }) {
    const headerHeight = '80px'; // Define the header height
    const footerHeight = '10px'; // Define the footer height for padding

    return (
        <div className="layout-container" style={{ minWidth: '360px', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Conditionally render Header */}
            {showHeader && <Header />}

            {/* Main content area with max-width restriction */}
            <Box
                sx={{
                    paddingTop: showHeader ? headerHeight : 0, // Add padding if the header is shown
                    paddingBottom: showFooter ? footerHeight : 0, // Add padding if the footer is shown
                    maxWidth: '750px',
                    width: '100%',
                    margin: '0 auto', // Center the content horizontally
                    flex: '1', // Allow this content area to expand and take up the available space
                }}
            >
                <main className="main-content">
                    {children}
                </main>
            </Box>

            {/* Conditionally render Footer */}
            {showFooter && <Footer />}
        </div>
    );
}

export default Layout;

import React from 'react';
import { Box, Typography, Link } from '@mui/material'; // Import MUI components
import MapIcon from '@mui/icons-material/Map';

function LocationDetailsCard({ initial_text, address, city, time }) {
    // Create a Google Maps link for the address
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

    // Function to format time into 12-hour format with AM/PM
    const formatTimeTo12Hour = (timeString) => {
        const [hour, minute] = timeString.split(':');
        let hourInt = parseInt(hour, 10);
        const ampm = hourInt >= 12 ? 'PM' : 'AM';
        hourInt = hourInt % 12 || 12; // Convert to 12-hour format, handling 0 and 12 as edge cases
        return `${hourInt}:${minute} ${ampm}`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 0,
                borderRadius: 1,
                backgroundColor: 'background.paper',
                width: '100%',
                marginBottom: 2,
            }}
        >
            {/* First Line: Initial text and formatted time */}
            <Typography variant="body2" sx={{ color: 'text.secondary'}}>
                {initial_text} {formatTimeTo12Hour(time)}
            </Typography>

            {/* Second Line: Address and Map Icon */}
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary', marginRight: 1 }}>
                    {address}
                </Typography>

                {/* Map Icon Link */}
                <Link
                    href={googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ display: 'flex', alignItems: 'center', color: 'primary.main' }} // Keep map icon next to address
                >
                    <MapIcon sx={{ fontSize: '20px' }} />
                </Link>
            </Box>
        </Box>
    );
}

export default LocationDetailsCard;

import React, {useState} from 'react';
import { Button, Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import apiConfig from "../../../config/apiConfig";

function ManagePassengerStatus({ profile, onStatusChange }) {
    const theme = useTheme(); // Access the theme for colors
    const [status, setStatus] = useState(profile.status); // Keep track of the status
    const [loading, setLoading] = useState(false);

    // Function to handle status update
    const updatePassengerStatus = async (newStatus) => {
        try {
            setLoading(true);

            const response = await fetch(`${apiConfig.baseUrl}/api/rides/update-passenger-segment-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    segmentIds: profile.segment_ids, // Use the segments associated with the profile
                    profile_id: profile.profile_id,   // Use the profile_id
                    status: newStatus,                // Pass the selected status
                }),
            });

            if (response.ok) {
                setStatus(newStatus);  // Update the status state
                onStatusChange();      // Call the status change handler to reload the component
            } else {
                console.error(`Failed to update status. Status code: ${response.status}`);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            setLoading(false);
        }
    };

    // Do not render the component if the status is "Declined"
    if (status === 'Declined') {
        return null;
    }

    // Show the accepted message if the status is "Accepted"
    if (status === 'Accepted') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    padding: 2,
                    width: '100%',
                    backgroundColor: theme.palette.secondary.main, // Background color
                    borderRadius: 2, // Rounded corners
                }}
            >
                {/* Accepted message */}
                <Typography variant="h6" sx={{ textAlign: 'center', color: theme.palette.secondary.contrastText }}>
                    <CheckIcon sx={{ color: theme.palette.primary.main, marginRight: 1 }} />
                    {profile.first_name} has been accepted
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                padding: 2,
                width: '100%',
                backgroundColor: theme.palette.secondary.main, // Background color
                borderRadius: 2, // Rounded corners
            }}
        >
            {/* Header */}
            <Typography variant="h6" sx={{ textAlign: 'center', color: theme.palette.secondary.contrastText }}>
                Manage passenger status for {profile.first_name}
            </Typography>

            {/* Buttons row */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                    gap: 2,
                }}
            >
                {/* Accept Button (2/3 of the width) */}
                <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() => updatePassengerStatus('Accepted')}
                    disabled={loading} // Disable while loading
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        width: '66%', // 2/3 width
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark, // Darken on hover
                        },
                    }}
                >
                    Accept
                </Button>

                {/* Decline Button */}
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={() => updatePassengerStatus('Declined')}
                    disabled={loading} // Disable while loading
                    sx={{
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.main,
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover, // Slightly darken on hover
                        },
                    }}
                >
                    Decline
                </Button>
            </Box>
        </Box>
    );
}

export default ManagePassengerStatus;

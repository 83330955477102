import React, { useState } from 'react';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'; // MUI edit icon
import apiConfig from "../../../config/apiConfig";

const ProfileEditPhotoCard = ({ profile_ID, onSave }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(null);

    // Open and close modal functions
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setError(null);
        setSelectedFile(null); // Clear file selection on close
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setError(null); // Clear error when a new file is selected
    };

    // Save selected photo
    const handleSavePhoto = async () => {
        if (!selectedFile) {
            setError("Please select a photo.");
            return;
        }

        const formData = new FormData();
        formData.append('profilePicture', selectedFile);
        formData.append('profile_ID', profile_ID);

        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/update-user-photo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: formData,
            });

            if (response.ok) {
                console.log('Photo uploaded successfully');
                onSave();
                handleCloseModal();
            } else {
                setError('Failed to upload photo.');
                console.error('Failed to upload photo');
            }
        } catch (error) {
            console.error('Error uploading photo:', error);
            setError('An error occurred while uploading the photo.');
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: '#fff', // White background
                    borderRadius: '50%', // Circular background
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: 1, // Subtle shadow for depth
                }}
            >
                <IconButton onClick={handleOpenModal} color="primary">
                    <EditIcon fontSize="30px" />
                </IconButton>
            </Box>

            {/* Modal for Editing Profile Photo */}
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
                <DialogTitle sx={{ textAlign: 'center' }}>Upload a New Profile Photo</DialogTitle>
                <DialogContent>
                    {error && (
                        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <Box my={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            sx={{ textTransform: 'none', mb: 1 }}
                        >
                            Choose Photo
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden // Hide default file input
                            />
                        </Button>
                        {selectedFile && (
                            <Typography variant="body2" color="textSecondary">
                                {selectedFile.name}
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSavePhoto} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            color: 'primary.main',
                            borderColor: 'primary.main',
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfileEditPhotoCard;

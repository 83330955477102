import React, { useState, useEffect } from 'react';
import { useProfile } from '../../context/ProfileContext'; // Import useProfile for Supabase context
import './Profile.css';
import ProfileEmailVerificationCard from './profile_cards/ProfileEmailVerificationCard';
import ProfileBioCard from "./profile_cards/ProfileBioCard";
import ProfileCarCard from "./profile_cards/ProfileCarCard";
import ProfilePreferencesCard from "./profile_cards/ProfilePreferencesCard";
import ProfileEditPhotoCard from "./profile_cards/ProfileEditPhotoCard";
import ProfileRatingTile from "./profile_tiles/ProfileRatingTile";
import ProfileTotalRidesTile from "./profile_tiles/ProfileTotalRidesTile";
import ProfilePhoneVerificationCard from "./profile_cards/ProfilePhoneVerificationCard";
import ProfileIDVerificationCard from "./profile_cards/ProfileIDVerificationCard";
import { Container, Grid2, Box, Typography, Avatar } from '@mui/material';
import StudentVerificationCard from "./profile_cards/StudentVerificationCard";
import apiConfig from "../../config/apiConfig";

function Profile() {
    const { user, isAuthenticated, loadingProfile } = useProfile(); // Use the ProfileContext for user data
    const [profileData, setProfileData] = useState({}); // State to store profile data
    const [error, setError] = useState(null); // State to store any errors
    const [fullyVerified, setFullyVerified] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    // Fetch the user's profile data from your backend
    const fetchUserProfile = async (supabase_user_id) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ UID: supabase_user_id }), // Send the user ID in the request body
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Retrieved user:", data);
                setProfileData(data); // Store the profile data in the state
            } else {
                throw new Error('Failed to fetch profile data.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            setError('An error occurred while fetching the profile data.');
        }
    };

    // Effect to run when the user logs in and is authenticated
    useEffect(() => {
        if (!loadingProfile && isAuthenticated && user?.id) {
            fetchUserProfile(user.id).then(() => {});
            console.log("URL for profile picture:", profileData.profile_picture);
        }
    }, [loadingProfile, isAuthenticated, user, refreshKey, profileData.profile_picture]);

    // Handler to update preferences after modal closes
    const updatePreferencesInProfile = (newPreferences) => {
        setProfileData((prevData) => ({
            ...prevData,
            ...newPreferences,
        }));
    };

    const triggerUpdate = () => {
        setRefreshKey((prevKey) => prevKey + 1); // Update the key to trigger a re-fetch
    };

    if (loadingProfile) {
        return <div>Loading...</div>; // Show a loading message while Supabase checks the user
    }

    if (error) {
        return <div>{error}</div>; // Show any errors that occur
    }

    return (
        <Container maxWidth="sm"> {/* maxWidth 'sm' for better mobile handling */}
            <Box sx={{ my: 3, textAlign: 'center' }}>
                <Grid2
                    container
                    spacing={{ xs: 2, md: 10 }} // 2 units for mobile, 10 units for desktop
                    alignItems="center"
                    justifyContent="center"
                    direction={{ xs: 'column', md: 'row' }} // Column layout below 960px, row layout at 960px and above
                >
                    {/* Welcome Text */}
                    <Grid2
                        item
                        xs={12}
                        md={8}
                        sx={{ textAlign: 'center' }}
                    >
                        <Typography variant="h4" sx={{ mb: { xs: 0, md: 0 } }}>
                            Hi, {profileData ? profileData.first_name : 'Hi!'}
                        </Typography>
                    </Grid2>

                    {/* Profile Picture with Edit Icon */}
                    <Grid2
                        item
                        xs={12}
                        md={4}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                width: 128,
                                height: 128,
                                borderRadius: '50%',
                                border: '2px solid',
                                borderColor: 'primary.main',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                alt="Profile Picture"
                                src={`${profileData?.profile_picture || '/content/profile/default_profile_picture.svg'}?t=${new Date().getTime()}`}
                                sx={{ width: 128, height: 128 }}
                            />
                        </Box>
                        {/* Edit Icon positioned on the lower right edge */}
                        <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                            <ProfileEditPhotoCard
                                profile_ID={profileData.profile_id}
                                onSave={triggerUpdate}
                            />
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>

            {/* Bio Card */}
            <Box sx={{ my: 1 }}>
                <ProfileBioCard
                    bio={profileData?.bio_text}
                    textIfUnfilled="Introduce yourself with a short bio"
                    profile_id={profileData.profile_id}
                />
            </Box>

            {/* Preferences */}
            {profileData && (
                <ProfilePreferencesCard
                    label="Update your ride preferences"
                    profile_id={profileData.profile_id}
                    preference_conversation={profileData.preference_conversation}
                    preference_music={profileData.preference_music}
                    onSave={updatePreferencesInProfile}
                />
            )}

            {/* Verification */}
            <Typography variant="h6" sx={{ my: 2 }}>
                {fullyVerified ? "You Are Fully Verified" : "Verify Your Profile"}
            </Typography>
            <ProfileEmailVerificationCard
                email={profileData?.email_address}
                verification_status={profileData.verified_email}
            />
            <ProfilePhoneVerificationCard
                phone_number={profileData.phone_number}
                verification_status={profileData.verified_phone}
                onSave={triggerUpdate}
                profile_id={profileData.profile_id}
            />
            <ProfileIDVerificationCard
                verified_ID={profileData?.verified_ID}
            />
            <StudentVerificationCard
                verified_student={profileData?.verified_student}
            />

            {/* Stats Section */}
            <Typography variant="h6" sx={{ my: 2 }}>
                {"Ratings and Stats"}
            </Typography>

            {profileData.num_completed_rides_driver || profileData.num_completed_rides_rider ? (
                <Grid2 container spacing={2}>
                    {profileData.num_completed_rides_driver > 0 && (
                        <Grid2 item xs={6}>
                            <ProfileRatingTile
                                label="Driver Rating"
                                rating={profileData.driver_rating || '0'}
                            />
                            <ProfileTotalRidesTile
                                label="Total Trips Driven"
                                ridesCompleted={profileData.num_completed_rides_driver}
                            />
                        </Grid2>
                    )}

                    {profileData.num_completed_rides_rider > 0 && (
                        <Grid2 item xs={6}>
                            <ProfileRatingTile
                                label="Passenger Rating"
                                rating={profileData.rider_rating || '0'}
                            />
                            <ProfileTotalRidesTile
                                label="Total Passenger Journeys"
                                ridesCompleted={profileData.num_completed_rides_rider}
                            />
                        </Grid2>
                    )}
                </Grid2>
            ) : (
                <Typography variant="body1" color="text.secondary" sx={{ my: 2 }}>
                    No completed rides yet
                </Typography>
            )}

            {/* Driver Section */}
            <Typography variant="h6" sx={{ my: 2 }}>
                {profileData.cars ? "Your Car" : "Save a Vehicle to Begin Posting Rides"}
            </Typography>
            {/* Cars */}
            {profileData.cars?.length ? (
                profileData.cars.map((car, index) => (
                    <ProfileCarCard
                        key={index}
                        carMake={car.make}
                        carModel={car.model}
                        carColor={car.color}
                        carLicense={car.license_plate_number}
                        profileId={profileData.profile_id}
                        onSave={triggerUpdate}
                    />
                ))
            ) : (
                <ProfileCarCard profileId={profileData.profile_id} />
            )}
        </Container>
    );
}

export default Profile;

import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material'; // Import MUI components
import SendIcon from '@mui/icons-material/Send'; // Import the Send (Paper Airplane) icon

function MessagesInput({ onSendMessage }) {
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (message.trim() !== '') {
            onSendMessage(message);
            setMessage(''); // Clear the input field after sending the message
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'background.paper', // Use theme background color
                padding: 2,
                zIndex: 10,
                boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Box with max width for content */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '750px', // Ensure consistent width with other areas
                    width: '100%', // Ensure the input field and button stretch to fit the width
                }}
            >
                <TextField
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={4}
                    value={message}
                    onChange={handleChange}
                    placeholder="Write a message..."
                    variant="outlined"
                    sx={{
                        backgroundColor: 'background.default',
                        borderRadius: '16px',
                        marginRight: '8px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'text.secondary', // Border color from the theme
                            },
                            '&:hover fieldset': {
                                borderColor: 'primary.main', // Primary color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'primary.main', // Focused border color
                            },
                        },
                    }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendMessage}
                    sx={{
                        padding: '12px',
                        backgroundColor: 'primary.main', // Primary color for the button
                        color: 'white',
                        borderRadius: '50%', // Circular icon button
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                        },
                    }}
                >
                    <SendIcon sx={{ fontSize: '24px' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default MessagesInput;

import React, { useState } from 'react';
import {
    Card,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Slide
} from '@mui/material';
import country_codes from '../../../config/country_codes.json';
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import apiConfig from "../../../config/apiConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfilePhoneVerificationCard = ({ phone_number, verification_status, onSave, profile_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countryCode, setCountryCode] = useState('+1');
    const [phone, setPhone] = useState(phone_number || '');
    const [codeSent, setCodeSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [isClosing, setIsClosing] = useState(false);
    const [verificationVerified, setVerificationVerified] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsClosing(false); // Reset isClosing when opening the modal
    };

    const handleCloseModal = () => {
        setIsClosing(true); // Set isClosing to disable the transition on close
        setTimeout(() => setIsModalOpen(false), 300); // Wait for the transition duration, then close modal
    };

    const handleCountryCodeChange = (event) => setCountryCode(event.target.value);
    const handlePhoneNumberChange = (event) => setPhone(event.target.value);
    const handleVerificationCodeChange = (event) => {
        const code = event.target.value;
        setVerificationCode(code);
        if (code.length === 6) verifyCode(code, countryCode + phone);
    };

    const sendVerificationCode = async (phone_number) => {
        try {
            console.log("trying - sending verification code");
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/send-verification-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ phone_number, method: 'sms' })
            });
            if (response.ok) setCodeSent(true);
        } catch (error) {
            console.error('Error sending verification code:', error);
        }
    };

    const verifyCode = async (code, phone_number) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/verify-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ code, phone_number, method: 'sms', profile_id })
            });
            if (response.ok) {
                const result = await response.json();
                console.log('Code verified successfully:', result);
                setVerificationVerified(true); // Set verification as successful
                onSave(); // or call a callback to update the state externally
                handleCloseModal(); // Close the modal if verification is successful
            } else {
                console.error('Failed to verify code:', response.statusText);
                setVerificationVerified(false); // Keep verification as unsuccessful
            }
        } catch (error) {
            console.error('Error verifying code:', error);
        }
    };

    const handleSubmit = async () => {
        const fullPhoneNumber = countryCode + phone;
        await sendVerificationCode(fullPhoneNumber);
    };

    return (
        <>
            <Card
                onClick={handleOpenModal}
                sx={{
                    cursor: 'pointer',
                    mb: 2,
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                    color: 'text.primary',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ color: 'primary.main', mr: 2, ml: 2 }}>
                    {phone_number ? (
                        verification_status ? (
                            <CheckCircleIcon fontSize="small" />
                        ) : (
                            <HourglassEmptyIcon fontSize="small" />
                        )
                    ) : (
                        <AddIcon fontSize="small" />
                    )}
                </Box>

                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                    {phone_number || 'Add a phone number'}
                </Typography>
            </Card>

            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                fullScreen
                TransitionComponent={!isClosing ? Transition : undefined} // Apply transition only on open
            >
                <DialogTitle sx={{ textAlign: 'center' }}>Enter Your Phone Number</DialogTitle>

                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 0,
                        maxWidth: 750,
                        width: '100%',
                        mx: 'auto',
                    }}
                >
                    {/* Country Code Select */}
                    <FormControl
                        fullWidth
                        sx={{
                            mt: 3, // Add margin to the first input box
                            mb: 2,
                            backgroundColor: 'secondary.main',
                            borderRadius: 2,
                            '& .MuiInputLabel-root': {
                                backgroundColor: 'white',
                                px: 1,
                                borderRadius: 10,
                            },
                        }}
                    >
                        <InputLabel>Country</InputLabel>
                        <Select value={countryCode} onChange={handleCountryCodeChange}>
                            {country_codes.map((country, index) => (
                                <MenuItem key={index} value={country.dial_code}>
                                    {country.name} ({country.dial_code})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Phone Number Input */}
                    <TextField
                        type="tel"
                        label="Phone Number"
                        value={phone}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter your phone number"
                        fullWidth
                        sx={{
                            backgroundColor: 'secondary.main',
                            borderRadius: 2,
                            '& .MuiInputLabel-root': {
                                backgroundColor: 'white',
                                px: 1,
                                borderRadius: 10,
                            },
                        }}
                    />

                    {/* Left-Aligned Receive Verification Code Button */}
                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2, alignSelf: 'flex-start' }}>
                        Receive Verification Code
                    </Button>

                    {/* Verification Code Input Section */}
                    {codeSent && (
                        <Box sx={{ mt: 3, textAlign: 'center', width: '100%', maxWidth: 400 }}>
                            <Typography variant="h6">Enter the 6-digit code sent to your phone</Typography>
                            <TextField
                                type="text"
                                value={verificationCode}
                                onChange={handleVerificationCodeChange}
                                placeholder="Enter the 6-digit code"
                                fullWidth
                                inputProps={{ maxLength: 6 }}
                                sx={{ mt: 1 }}
                            />
                        </Box>
                    )}
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'flex-end',
                        maxWidth: 750, // Apply max width to keep actions aligned with content
                        width: '100%',
                        mx: 'auto',
                        pr: 2,
                    }}
                >
                    {/* Display Save button only if the verification code has been received and verified */}
                    {verificationCode.length === 6 && verificationVerified && (
                        <Button onClick={onSave} variant="contained" color="primary">
                            Save
                        </Button>
                    )}
                    <Button
                        onClick={handleCloseModal}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            borderColor: 'secondary.main',
                            color: 'primary.main',
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfilePhoneVerificationCard;

import React, { useState } from 'react';
import './FormStyle.css'; // Reusing the same CSS

function BirthdayInput({ label, onChange, onSubmit, buttonLabel }) {
    // State variables for the month, day, and year
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');

    // Handle input change for each field and pass the combined value to parent
    const handleInputChange = (e, field) => {
        const value = e.target.value;

        if (field === 'month') setMonth(value);
        if (field === 'day') setDay(value);
        if (field === 'year') setYear(value);

        // Call onChange with the current state of all fields
        onChange({ month: field === 'month' ? value : month, day: field === 'day' ? value : day, year: field === 'year' ? value : year });
    };

    return (
        <div className="input-component-container">
            <label className="input-label" htmlFor="birthday">{label}</label>
            <div className="birthday-input-container">
                {/* Month Input */}
                <input
                    type="text"
                    id="month"
                    value={month}
                    onChange={(e) => handleInputChange(e, 'month')}
                    placeholder="MM"
                    className="birthday-input-section"
                    maxLength="2" // Restrict to 2 digits
                />

                <span className="slash-separator">/</span>

                {/* Day Input */}
                <input
                    type="text"
                    id="day"
                    value={day}
                    onChange={(e) => handleInputChange(e, 'day')}
                    placeholder="DD"
                    className="birthday-input-section"
                    maxLength="2" // Restrict to 2 digits
                />

                <span className="slash-separator">/</span>

                {/* Year Input */}
                <input
                    type="text"
                    id="year"
                    value={year}
                    onChange={(e) => handleInputChange(e, 'year')}
                    placeholder="YYYY"
                    className="birthday-input-section"
                    maxLength="4" // Restrict to 4 digits
                />
            </div>
            <button onClick={onSubmit} className="form-submit-button">
                {buttonLabel}
            </button>
        </div>
    );
}

export default BirthdayInput;

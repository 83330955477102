import React, {useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import SearchInput from "../../../components/ride_search_bar/SearchInput";
import NextStepButton from "../Components/NextStepButton"; // Adjust the path as needed
import {Box, Typography, Grid, Divider, Modal, IconButton} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import SearchInputButton from "../../../components/ride_search_bar/SearchInputButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function PostRideAddStops() {
    const location = useLocation();
    const numberOfPassengers = "", rideDescription = "";

    // Destructure the state passed via navigate
    const { departureLocation, departureCity, departureState, departureType, arrivalLocation, arrivalCity, arrivalState, arrivalType, formattedDate, roundTrip } = location.state || {};
    const departureLocationInfo = {
        location: departureLocation,
        city: departureCity,
        state: departureState,
        type: departureType,
    };
    const arrivalLocationInfo = {
        location: arrivalLocation,
        city: arrivalCity,
        state: arrivalState,
        type: arrivalType,
    };

    const [stopovers, setStopovers] = useState([]);
    const [currentStopover, setCurrentStopover] = useState({ location: '', city: '', state: '' });
    const [itemSelected, setItemSelected] = useState(false); // New flag to detect item selection
    const inputRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (modalOpen && inputRef.current) {
            inputRef.current.focus(); // Focus on the input when modal opens
        }
    }, [modalOpen]);

    // Call `addStopover` only when `itemSelected` is true and `currentStopover` has updated
    useEffect(() => {
        if (itemSelected) {
            addStopover();
            setItemSelected(false); // Reset the flag after adding the stopover
        }
    }, [currentStopover, itemSelected]); // Run when `currentStopover` or `itemSelected` changes

    // Handle adding a stopover
    const addStopover = () => {
        setStopovers([...stopovers, currentStopover]);
        console.log("addStopover --- current stopovers: ", [...stopovers, currentStopover]);
        setCurrentStopover({ location: '', city: '', state: '' }); // Clear the current stopover after adding
    };

    // Handle deleting a stopover
    const deleteStopover = (index) => {
        const updatedStops = stopovers.filter((_, i) => i !== index);
        setStopovers(updatedStops);
    };

    return (
        <Box
            sx={{
                maxWidth: 800,
                p: 0, // Remove padding for flat display
                bgcolor: 'transparent', // Transparent background
                boxShadow: 'none', // No shadow for flat display
                mb: 5, // 5-unit margin at the bottom
                mx: 2, // Minimum 2-unit margin on left and right
            }}
        >
            {/* Back Arrow Button positioned below header and left-aligned with content */}
            <IconButton
                sx={{
                    position: 'relative',
                    top: 10, // Positioning below the header
                    alignSelf: 'flex-start', // Align left within the bounding box
                    mb: 1, // Add margin below the back arrow
                }}
                onClick={() => window.history.back()} // Navigate back to previous page
            >
                <ArrowBackIcon />
            </IconButton>

            <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                Add Pickup Points
            </Typography>

            {/* Informational Text */}
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Adding pickup points increases the chance of matching with a passenger.
            </Typography>

            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={0} alignItems="center">
                {/* Disabled Search Input for Departure Location */}
                <Grid item xs={12}>
                    <SearchInputButton
                        placeholder={departureLocation}
                        mode="disabled"
                        fullWidth
                        noRoundedCorners
                    />
                </Grid>

                {/* Render added stops as disabled SearchInputButton */}
                {stopovers.map((stopover, index) => (
                    <Grid item xs={12} key={index}>
                        <SearchInputButton
                            address={stopover.location}
                            placeholder="Stopover"
                            mode="disabled" // Disabled mode for added stops
                            fullWidth
                            noRoundedCorners
                            onDelete={() => {
                                deleteStopover(index);
                            }}
                        />
                    </Grid>
                ))}

                {/* Search Input for Adding Stop */}
                <Grid item xs={12}>
                    <SearchInputButton
                        address={currentStopover.location}
                        placeholder="Add Stop"
                        onClick={() => setModalOpen(true)}
                        onDelete={() => {
                            setCurrentStopover({ location: '', city: '', state: '' });
                            setModalOpen(true);
                        }}
                        fullWidth
                        noRoundedCorners
                    />
                </Grid>

                {/* Disabled Search Input for Arrival Location */}
                <Grid item xs={12}>
                    <SearchInputButton
                        placeholder={arrivalLocation}
                        mode="disabled"
                        fullWidth
                        noRoundedCorners
                    />
                </Grid>
            </Grid>

            <Box sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'flex-end', // Align to the right
            }}>
                <NextStepButton
                    label="Next Step"
                    redirectPath="/post-ride-set-times"
                    locations={[departureLocationInfo, ...stopovers, arrivalLocationInfo]}
                    formattedDate={formattedDate}
                    numberOfPassengers={numberOfPassengers}
                    rideDescription={rideDescription}
                    segments={[]}
                    roundTrip={roundTrip}
                />
            </Box>

            {/* Modal for Adding Stops */}
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    width: '100vw',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    zIndex: 1300,
                }}
            >
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        padding: 0,
                        backgroundColor: 'white',
                        margin: 0,
                        borderRadius: 0,
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 'bold',
                                textAlign: 'left',
                            }}
                        >
                            Add a pickup point
                        </Typography>

                        {/* Close Icon */}
                        <IconButton
                            sx={{
                                marginLeft: 'auto',
                            }}
                            onClick={() => setModalOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <SearchInput
                        ref={inputRef}
                        placeholder="Add Stop"
                        address={currentStopover.location}
                        mode="wide"
                        handleAddressChange={({ address, place, state }) => {
                            setCurrentStopover({
                                location: address,
                                city: place,
                                state: state
                            });
                        }}
                        onItemClick={() => {
                            setItemSelected(true); // Flag to trigger `useEffect` for adding stopover
                            setModalOpen(false); // Close the modal
                        }}
                    />
                </Box>
            </Modal>
        </Box>
    );
}

export default PostRideAddStops;

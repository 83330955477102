import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabase auth/supabaseClient'; // Ensure the path is correct for your project

// Create the context
const ProfileContext = createContext();

// Custom hook to use the ProfileContext
export const useProfile = () => useContext(ProfileContext);

// Create a provider component that manages user authentication and profile fetching
export const ProfileProvider = ({ children }) => {
    const [profile_id, setProfile_id] = useState(null); // State to store the profile ID
    const [loadingProfile, setLoadingProfile] = useState(true); // Loading state for profile
    const [user, setUser] = useState(null); // State to store the authenticated user
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authenticated state

    useEffect(() => {
        // Check the current session and set the user state accordingly
        const checkSession = async () => {
            const {
                data: { session },
            } = await supabase.auth.getSession();
            setUser(session?.user ?? null);

            if (session) {
                setIsAuthenticated(true);
                fetchUserProfile(session.user.id);
            } else {
                setIsAuthenticated(false);
                setLoadingProfile(false); // Set loading to false if not authenticated
            }
        };

        // Listen for auth state changes (login, logout, token refresh)
        const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
            setUser(session?.user ?? null);
            if (session) {
                setIsAuthenticated(true);
                fetchUserProfile(session.user.id);
            } else {
                setIsAuthenticated(false);
                setProfile_id(null);
                setLoadingProfile(false);
            }
        });

        checkSession();

        // Cleanup listener on component unmount
        return () => {
            subscription?.unsubscribe?.(); // Safely call unsubscribe if it exists
        };
    }, []);

    const fetchUserProfile = async (supabase_user_id) => {
        try {
            const response = await fetch(`/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089' // Replace with actual API key management practice
                },
                body: JSON.stringify({ UID: supabase_user_id }),
            });

            if (response.ok) {
                const data = await response.json();
                setProfile_id(data.profile_id); // Update profile_id in state
            } else {
                console.error('Failed to fetch profile data.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoadingProfile(false); // Set loading to false when done
        }
    };

    return (
        <ProfileContext.Provider value={{ user, profile_id, isAuthenticated, loadingProfile }}>
            {children}
        </ProfileContext.Provider>
    );
};

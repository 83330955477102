import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';


function TimePicker({ label, value, onChange }) {
    const times = [];

    // Generate times in 12-hour AM/PM format with 10-minute intervals
    for (let i = 1; i <= 12; i++) {
        for (let j = 0; j < 60; j += 10) {
            const hour = i < 10 ? `0${i}` : i;
            const minute = j === 0 ? '00' : j;
            times.push(`${hour}:${minute} AM`);
        }
    }

    for (let i = 1; i <= 12; i++) {
        for (let j = 0; j < 60; j += 10) {
            const hour = i < 10 ? `0${i}` : i;
            const minute = j === 0 ? '00' : j;
            times.push(`${hour}:${minute} PM`);
        }
    }

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={value}
                    onChange={onChange}
                    label={label} // Make sure the label and select work together
                >
                    {times.map((time, index) => (
                        <MenuItem key={index} value={time}>
                            {time}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default TimePicker;

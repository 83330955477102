import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from '@auth0/auth0-react';
import {BrowserRouter as Router} from 'react-router-dom'; // Use BrowserRouter here
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';

// Import LocalizationProvider and AdapterDateFns
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'; // For using date-fns

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const rootElement = document.getElementById('root'); // Get the root element
const root = ReactDOM.createRoot(rootElement); // Use createRoot from react-dom/client

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Auth0Provider
                domain={domain}
                clientId={clientId}
                authorizationParams={{redirect_uri: window.location.origin}} // Pass authorizationParams as an object
            >
                <Router>
                    {/* Wrap the entire app in LocalizationProvider */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App/>
                    </LocalizationProvider>
                </Router>
            </Auth0Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import React from "react";

function Home() {
    return (
        <div className="About-container">
            Hello World!
        </div>
    );
}

export default Home;

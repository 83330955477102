import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import { format } from "date-fns";
import { useProfile } from '../../context/ProfileContext';  // Import the custom hook
import apiConfig from "../../config/apiConfig";

function SearchButton({
                          departureAddress,
                          departureName,
                          departureCity,
                          departureState,
                          departureType,
                          arrivalAddress,
                          arrivalName,
                          arrivalCity,
                          arrivalState,
                          arrivalType,
                          travelDate,
                          mode,
                          roundTrip = false,
                          buttonLabel = "Search"
                      }) {
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const { profile_id, loadingProfile } = useProfile();  // Access profile_id from context

    // Function to add search history via the API
    const addSearchHistory = async () => {
        if (!loadingProfile && profile_id) {
            try {
                const response = await fetch(`${apiConfig.baseUrl}/api/rides/add-search-history`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({
                        profile_id: profile_id,
                        departureName: departureName || null,
                        departureAddress: departureAddress || null,
                        departureCity: departureCity || null,
                        departureState: departureState || null,
                        departureType: departureType || null,
                        arrivalName: arrivalName || null,
                        arrivalAddress: arrivalAddress || null,
                        arrivalCity: arrivalCity || null,
                        arrivalState: arrivalState || null,
                        arrivalType: arrivalType || null,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to add search history');
                }

                console.log('Search history added successfully');
            } catch (error) {
                console.error('Error adding search history:', error);
            }
        }
    };

    const handleSearch = () => {
        try {
            // Ensure there are items in both dropdowns
            if (arrivalAddress && departureAddress && travelDate) {
                // Format the date value into yyyy-mm-dd format
                let formattedDate = travelDate instanceof Date && !isNaN(travelDate)
                    ? format(travelDate, 'yyyy-MM-dd')
                    : travelDate;

                // Call the addSearchHistory function
                addSearchHistory();

                if (!mode) {
                    // Encode the input values to be used in the URL
                    const selectedDepartureAddress = encodeURIComponent(departureAddress);
                    const selectedArrivalAddress = encodeURIComponent(arrivalAddress);

                    // Construct the URL
                    const searchUrl = `/rides?departure_location=${selectedDepartureAddress}&departure_city=${departureCity}&arrival_location=${selectedArrivalAddress}&arrival_city=${arrivalCity}&date=${encodeURIComponent(formattedDate)}&round_trip=${roundTrip}`;

                    // Navigate and reload the page
                    window.location.href = searchUrl;  // This forces a full page reload

                } else {
                    navigate('/post-ride-add-stops', {
                        state: {
                            departureLocation: departureAddress,
                            departureCity,
                            departureState,
                            arrivalLocation: arrivalAddress,
                            arrivalCity,
                            arrivalState,
                            formattedDate: encodeURIComponent(formattedDate), // Ensure a key is provided for formattedDate
                            roundTrip,
                        }
                    });
                }

            } else {
                console.log("No items in dropdowns");
            }
        } catch (error) {
            console.error('Error during search:', error);
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{
                px: 4,
                py: 1.5,
                width: '100%', // Make the button span the width of its container
                borderRadius: '0 0 16px 16px', // Top corners not rounded, bottom corners rounded
            }}
        >
            {buttonLabel}
        </Button>
    );
}

export default SearchButton;

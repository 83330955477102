import React, { useState } from 'react';
import { Card, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Slide, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from "@mui/icons-material/Edit";
import apiConfig from "../../../config/apiConfig";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileCarCard = ({ carMake, carModel, carColor, carLicense, profileId, onSave }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [make, setMake] = useState(carMake || '');
    const [model, setModel] = useState(carModel || '');
    const [color, setColor] = useState(carColor || '');
    const [licensePlate, setLicensePlate] = useState(carLicense || '');
    const [isClosing, setIsClosing] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsClosing(false); // Reset isClosing to ensure transitions work correctly
    };

    const handleCloseModal = () => {
        setIsClosing(true);
        setTimeout(() => setIsModalOpen(false), 300); // Close after transition duration
    };

    const handleSaveCar = async () => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/update-car`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    profile_id: profileId,
                    make,
                    model,
                    color,
                    license_plate_number: licensePlate,
                }),
            });

            if (response.ok) {
                console.log('Car information updated successfully');
                onSave();
                handleCloseModal();
            } else {
                console.error('Failed to update car information');
            }
        } catch (error) {
            console.error('Error updating car information:', error);
        }
    };

    return (
        <>
            <Card
                onClick={handleOpenModal}
                sx={{
                    cursor: 'pointer',
                    mb: 2,
                    boxShadow: 'none',
                    backgroundColor: 'secondary.main',
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    p: 1,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, ml: 2 }}>
                    {carMake && carModel && carColor ? (
                        <EditIcon fontSize="small" sx={{ color: 'primary.main' }} />
                    ) : (
                        <AddIcon fontSize="small" sx={{ color: 'primary.main' }} />
                    )}
                </Box>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {carMake && carModel && carColor ? `${carMake} ${carModel} (${carColor})` : 'Click here to add a vehicle'}
                </Typography>
            </Card>

            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                fullScreen
                TransitionComponent={!isClosing ? Transition : undefined} // Apply transition only on open
                maxWidth="sm"
            >
                <DialogTitle sx={{ textAlign: 'center' }}>{carMake ? 'Edit Vehicle' : 'Add Vehicle'}</DialogTitle>
                <DialogContent
                    sx={{
                        maxWidth: 750,
                        mx: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Car Make"
                        value={make}
                        onChange={(e) => setMake(e.target.value)}
                        placeholder="Enter car make"
                        fullWidth
                        sx={{ backgroundColor: 'secondary.main', borderRadius: 2, mt: 1 }}
                    />
                    <TextField
                        label="Car Model"
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        placeholder="Enter car model"
                        fullWidth
                        sx={{ backgroundColor: 'secondary.main', borderRadius: 2 }}
                    />
                    <TextField
                        label="Car Color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        placeholder="Enter car color"
                        fullWidth
                        sx={{ backgroundColor: 'secondary.main', borderRadius: 2 }}
                    />
                    <TextField
                        label="License Plate Number"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value)}
                        placeholder="Enter license plate number"
                        fullWidth
                        sx={{ backgroundColor: 'secondary.main', borderRadius: 2 }}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        maxWidth: 750,
                        mx: 'auto',
                        width: '100%',
                        justifyContent: 'flex-end',
                        p: 2,
                    }}
                >
                    <Button onClick={handleSaveCar} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="outlined"
                        sx={{
                            backgroundColor: 'white',
                            borderColor: 'secondary.main',
                            color: 'primary.main',
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProfileCarCard;

import React, { useEffect, useRef, useState } from 'react';
import MessagesInput from './messages_components/MessagesInput';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Typography, List, Paper, Avatar, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth0 } from "@auth0/auth0-react";
import apiConfig from "../../config/apiConfig";

function Messages() {
    const location = useLocation();
    const navigate = useNavigate();
    const { otherUserProfile } = location.state || {};
    const { user, isAuthenticated, isLoading } = useAuth0();

    const [currentProfileData, setCurrentProfileData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    const messageEndRef = useRef(null); // Keep track of the end of messages for auto-scrolling

    // Fetch the current user profile
    const fetchUserProfile = async (user) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({ auth0_user_id: user.sub }),
            });

            if (response.ok) {
                const data = await response.json();
                setCurrentProfileData(data);
            } else {
                throw new Error('Failed to fetch profile data.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch conversation messages between two users
    const fetchConversation = async () => {
        if (!currentProfileData || !otherUserProfile) return;
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/messages/get-conversation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    profile_id_1: currentProfileData.profile_id,
                    profile_id_2: otherUserProfile.profile_id,
                }),
            });

            const data = await response.json();
            if (Array.isArray(data)) {
                setMessages(data);
                await markMessagesAsRead(data);
            } else {
                setMessages([]);
            }
        } catch (error) {
            console.error('Error fetching conversation:', error);
            setMessages([]);
        } finally {
            setLoading(false);
        }
    };

    // Mark messages as read
    const markMessagesAsRead = async (messages) => {
        const unreadMessages = messages
            .filter(message => message.receiver_profile_id === currentProfileData?.profile_id && message.status !== 'read')
            .map(message => message.id);

        if (unreadMessages.length > 0) {
            try {
                await fetch('https://api.ridealong.us/api/messages/mark-as-read', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ message_ids: unreadMessages }),
                });
            } catch (error) {
                console.error('Error marking messages as read:', error);
            }
        }
    };

    // Send a new message
    const handleSendMessage = async (messageContent) => {
        try {
            const response = await fetch('https://api.ridealong.us/api/messages/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                },
                body: JSON.stringify({
                    sender_profile_id: currentProfileData.profile_id,
                    receiver_profile_id: otherUserProfile.profile_id,
                    message_content: messageContent,
                }),
            });

            if (response.ok) {
                fetchConversation();
            } else {
                console.error('Error sending message.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // Fetch user profile when authenticated
    useEffect(() => {
        if (!isLoading && isAuthenticated && user) {
            fetchUserProfile(user);
        }
    }, [isLoading, isAuthenticated, user]);

    // Fetch conversation when profile data is available
    useEffect(() => {
        if (currentProfileData) {
            fetchConversation();
        }
    }, [currentProfileData, fetchConversation]);

    // Scroll to bottom when messages are updated
    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages])

    // Navigate to view profile
    const handleProfileClick = () => {
        navigate('/view-profile', { state: { driver: otherUserProfile } });
    };

    if (loading) {
        return <div>Loading messages...</div>;
    }

    return (
        <Box sx={{ maxWidth: '750px', margin: '0 auto', paddingX: 2 }}>
            {/* Back Arrow */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <IconButton
                    onClick={() => navigate(-1)}
                    sx={{
                        color: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'primary.dark',
                        },
                    }}
                    aria-label="go-back"
                >
                    <ArrowBackIcon sx={{ fontSize: '24px' }} />
                </IconButton>
            </Box>

            {/* Header Section */}
            <Paper
                sx={{
                    marginBottom: '8px',
                    backgroundColor: 'background.paper',
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    boxShadow: 'none', // No shadow when idle
                    '&:hover': {
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Shadow during hover
                    },
                }}
                onClick={handleProfileClick} // Navigate to profile on click
            >
                {/* Profile Picture */}
                <Avatar
                    src={otherUserProfile?.profile_picture || 'default-avatar.png'}
                    alt="Profile Picture"
                    sx={{ width: 60, height: 60, marginRight: 2 }}
                />

                {/* User Information */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                        {otherUserProfile?.first_name}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        Click to view profile
                    </Typography>
                </Box>
            </Paper>

            {/* Messages Container */}
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: 2,
                    backgroundColor: 'background.default',
                    marginBottom: 8,
                }}
            >
                {messages.length > 0 ? (
                    <List sx={{ width: '100%' }}>
                        {messages.map((message, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: message.sender_profile_id === currentProfileData.profile_id ? 'flex-end' : 'flex-start',
                                    mb: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '12px 16px',
                                        backgroundColor: message.sender_profile_id === currentProfileData.profile_id ? 'primary.main' : 'secondary.light',
                                        color: message.sender_profile_id === currentProfileData.profile_id ? 'primary.contrastText' : 'text.primary',
                                        borderRadius: 2,
                                        maxWidth: '75%',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: message.sender_profile_id === currentProfileData.profile_id ? 'background.default' : 'text.primary' }}>
                                        {message.message_content}
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: message.sender_profile_id === currentProfileData.profile_id ? 'background.default' : 'text.secondary' }}>
                                        {message.time_formatted}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </List>
                ) : (
                    <Typography>No messages available</Typography>
                )}
            </Box>

            {/* Messages Input */}
            <MessagesInput onSendMessage={handleSendMessage} />
        </Box>
    );
}

export default Messages;
// src/pages/Inbox.jsx
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react'; // Using Auth0 for authentication
import InboxCard from './inbox_components/InboxCard'; // Import the InboxCard component
import { Box, Typography } from '@mui/material';
import apiConfig from "../../config/apiConfig"; // MUI components for styling

function Inbox() {
    const { user, isAuthenticated } = useAuth0(); // Get the logged-in user from Auth0
    const [profile_id, setProfileId] = useState(null); // Store profile_id once fetched
    const [inboxData, setInboxData] = useState([]); // Store inbox data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Function to fetch the user's profile to get the profile_id
    const fetchUserProfile = async (auth0_user_id) => {
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089' // Replace with actual API key management practice
                },
                body: JSON.stringify({ auth0_user_id }),
            });

            if (response.ok) {
                const data = await response.json();
                setProfileId(data.profile_id); // Set the retrieved profile_id
            } else {
                throw new Error('Failed to fetch profile data.');
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            setError('An error occurred while fetching the profile data.');
        }
    };

    const fetchInboxData = async (profile_id) => {
        setLoading(true); // Set loading to true when fetching begins
        try {
            const response = await fetch(`${apiConfig.baseUrl}/api/messages/get-inbox?profile_id=${profile_id}`, {
                method: 'GET', // Changed to GET as it's more appropriate for query parameters
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089' // Replace with actual API key management practice
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch inbox data');
            }

            const data = await response.json();
            setInboxData(data); // Set the inbox data
        } catch (error) {
            console.error('Error fetching inbox data:', error);
            setError('Failed to load inbox');
        } finally {
            setLoading(false); // Ensure loading is set to false after fetching
        }
    };

    // Use effect to fetch the profile and inbox data when the user is authenticated
    useEffect(() => {
        if (isAuthenticated && user) {
            const auth0_user_id = user.sub; // Auth0 user ID
            fetchUserProfile(auth0_user_id); // Fetch the profile ID
        }
    }, [isAuthenticated, user]);

    // Use effect to fetch inbox data after profile_id is retrieved
    useEffect(() => {
        if (profile_id) {
            fetchInboxData(profile_id); // Fetch inbox data with the profile_id
        }
    }, [profile_id]);

    // Render the loading, error, or empty state
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Box
            sx={{
                maxWidth: '800px',
                margin: 'auto',
                padding: '0',
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom align="center">
                Your Inbox
            </Typography>
            {inboxData.length > 0 ? (
                inboxData.map((conversation) => (
                    <InboxCard
                        key={conversation.message_id} // Use a unique key like latest_message_time
                        conversation={conversation}
                        currentUserProfileID={profile_id}
                    />
                ))
            ) : (
                <Typography variant="body1" align="center">
                    No conversations yet.
                </Typography>
            )}
        </Box>
    );
}

export default Inbox;
// React and Hooks
import React, { useEffect, useRef, useState } from 'react';

// Custom Components
import SearchInput from './SearchInput';
import SearchInputButton from './SearchInputButton';
import SwapArrow from './SwapArrow';
import SearchButton from './SearchButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

// MUI Components
import {
    Box,
    Grid,
    IconButton,
    Modal,
    Typography,
    FormControlLabel,
    Switch,
    Tooltip,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";

// Utility Functions
import { parseISO } from 'date-fns';

function SearchBar({
                       initialDepartureAddress,
                       initialArrivalAddress,
                       initialTravelDate,
                       mode,
                       buttonLabel,
                       initialRoundTrip,
                       initialDepartureCity,
                       initialArrivalCity,
                       enableHistory = false  // Default value for enableHistory is set to false
                   }) {
    const [departureAddress, setDepartureAddress] = useState('');
    const [departureCity, setDepartureCity] = useState('');
    const [departureName, setDepartureName] = useState('');
    const [departureState, setDepartureState] = useState('');
    const [departureType, setDepartureType] = useState('');
    const [arrivalAddress, setArrivalAddress] = useState('');
    const [arrivalCity, setArrivalCity] = useState('');
    const [arrivalName, setArrivalName] = useState('');
    const [arrivalState, setArrivalState] = useState('');
    const [arrivalType, setArrivalType] = useState('');
    const [travelDate, setTravelDate] = useState('');
    const [roundTrip, setRoundTrip] = useState(initialRoundTrip);

    // Modal state management
    const [departureModalOpen, setDepartureModalOpen] = useState(false);
    const [arrivalModalOpen, setArrivalModalOpen] = useState(false);

    //refs
    const departureInputRef = useRef(null); // Create a ref for departure input
    const arrivalInputRef = useRef(null);   // Create a ref for arrival input

    useEffect(() => {
        // Update state variables only when props change
        if (initialDepartureAddress) setDepartureAddress(initialDepartureAddress);
        if (initialDepartureCity) setDepartureCity(initialDepartureCity);
        if (initialArrivalAddress) setArrivalAddress(initialArrivalAddress);
        if (initialArrivalCity) setArrivalCity(initialArrivalCity);
        if (initialTravelDate) setTravelDate(initialTravelDate);
        if (initialRoundTrip) setRoundTrip(initialRoundTrip);
    }, [initialDepartureAddress, initialDepartureCity, initialArrivalAddress, initialArrivalCity, initialTravelDate, initialRoundTrip]);

    useEffect(() => {
        if (departureModalOpen && departureInputRef.current) {
            departureInputRef.current.focus(); // Focus on the departure input when modal opens
        }
    }, [departureModalOpen]);

    useEffect(() => {
        if (arrivalModalOpen && arrivalInputRef.current) {
            arrivalInputRef.current.focus(); // Focus on the arrival input when modal opens
        }
    }, [arrivalModalOpen]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                width: '100%', // Ensures it takes up the full width of the container
                margin: '0', // Remove auto margin to allow full width
                position: 'relative',
                borderRadius: '16px', // Match the borderRadius of buttons
            }}
        >
            {/* Line 1: Departure Location Input */}
            <Grid item xs={12}>
                <SearchInputButton
                    address={departureAddress}
                    placeholder="From where?"
                    onClick={() => setDepartureModalOpen(true)}
                    onDelete={() => {
                        setDepartureAddress(''); // Clear the departure address
                        setDepartureModalOpen(true); // Open the departure modal
                    }}
                    fullWidth
                    noTopRoundedCorners
                    roundTrip={roundTrip}
                />
            </Grid>

            {/* Swap Arrow positioned directly on top of the divider line */}
            <Box
                sx={{
                    position: 'absolute', // Make the arrow float
                    top: {
                        xs: '20%', // Top position for mobile (you can modify this value)
                        md: '26%', // Top position for larger screens (you can modify this value)
                    },
                    left: {
                        xs: '90%', // Left position for mobile (you can modify this value)
                        md: '75%', // Left position for larger screens (you can modify this value)
                    },
                    transform: 'translate(-50%, -50%)', // Adjust the exact centering
                    zIndex: 1, // Ensure it stays above the divider
                    backgroundColor: '#fff',
                    border: '1px solid #e0e0e0',
                    borderRadius: '50%',
                    padding: '0px',
                }}
            >
                <SwapArrow
                    departureAddress={departureAddress}
                    arrivalAddress={arrivalAddress}
                    setDepartureAddress={setDepartureAddress}
                    setArrivalAddress={setArrivalAddress}
                />
            </Box>

            {/* Horizontal line between section 1 and 2 */}
            <Box sx={{ borderBottom: '1px solid #e0e0e0', margin: '2px 20px' }} />

            {/* Line 2: Arrival Location Input */}
            <Grid item xs={12}>
                <SearchInputButton
                    address={arrivalAddress}
                    placeholder="To where?"
                    onClick={() => setArrivalModalOpen(true)}
                    onDelete={() => {
                        setArrivalAddress(''); // Clear the arrival address
                        setArrivalModalOpen(true); // Open the arrival modal
                    }}
                    fullWidth
                    noRoundedCorners
                />
            </Grid>

            {/* Horizontal line between section 2 and 3 */}
            <Box sx={{ borderBottom: '1px solid #e0e0e0', margin: '2px 20px' }} />

            {/* Section 3 - Date picker and RT toggle */}
            <Grid container item>
                {/* DatePicker with reduced width and icon on the left */}
                <Grid item xs={12} md={4} sx={{ marginBottom: 0.25 }}>
                    <DatePicker
                        value={initialTravelDate ? parseISO(initialTravelDate) : null}
                        onChange={(newDate) => setTravelDate(newDate)} // Update travelDate when a new date is selected
                        slotProps={{
                            // Targets the `IconButton` component.
                            openPickerButton: {
                                color: 'primary',
                            },
                            // Targets the `InputAdornment` component.
                            inputAdornment: {
                                position: 'start',
                                sx: {
                                    marginLeft: '8px', // Adds Xpx margin to the left of the icon
                                },
                            },
                        }}
                        minDate={new Date()}  // Prevents selecting past dates
                    />
                </Grid>

                {/* Divider for mobile view only */}
                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' }, margin: '0 16px' }}>
                    <Box sx={{ borderBottom: '1px solid #e0e0e0' }} />
                </Grid>

                {/* Toggle for Round Trip with Info Icon */}
                <Grid
                    item
                    xs={12}
                    md={2} // Increase the width of this section to give more room for the text
                    sx={{
                        padding: '8px 2px',
                        textAlign: 'left', // Align left for both mobile and desktop
                        marginLeft: { xs: '4px', md: '160px' }, // Adjust margin for desktop
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            justifyContent: { xs: 'flex-start', md: 'flex-start' }, // Keep left-aligned for desktop and mobile
                            flexWrap: 'nowrap', // Prevent wrapping of the text and switch
                            gap: 0, // Add gap between items for proper spacing
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={roundTrip}
                                    onChange={() => setRoundTrip((prev) => !prev)}
                                    color="primary"
                                />
                            }
                            label="Same-day round trip only"
                            labelPlacement="end" // Keep label on the right side of the switch
                            sx={{
                                marginLeft: { xs: '8px', md: '0px' }, // Adjust margin for desktop and mobile
                                whiteSpace: 'nowrap', // Prevent the text from wrapping to a new line
                            }}
                        />

                        {/* Info Icon with Tooltip */}
                        <Tooltip
                            title={
                                <Box sx={{ p: 1 }}>
                                    When toggled on, only rides returning to the departure location on the same day will be shown. Perfect for a day trip to the ski mountain, beach, or nearby city.
                                </Box>
                            }
                            placement="top"
                            arrow
                            enterTouchDelay={0} // For mobile: show tooltip immediately on touch
                            leaveDelay={1000} // Tooltip will remain visible for 1 second longer after the mouse leaves
                        >
                            <IconButton sx={{ ml: 0, padding: 0 }}>
                                <InfoOutlined
                                    sx={{
                                        fontSize: '18px', // Make the icon smaller
                                        color: 'rgba(0, 0, 0, 0.54)', // Lighter color for a subtle look
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>

            {/* Line 4: Search Button */}
            <Grid item xs={12}>
                <SearchButton
                    departureAddress={departureAddress}
                    departureName={departureName}
                    departureCity={departureCity}
                    departureState={departureState}
                    departureType={departureType}
                    arrivalAddress={arrivalAddress}
                    arrivalName={arrivalName}
                    arrivalCity={arrivalCity}
                    arrivalState={arrivalState}
                    arrivalType={arrivalType}
                    travelDate={travelDate}
                    mode={mode}
                    roundTrip={roundTrip}
                    buttonLabel={buttonLabel}
                    fullWidth
                />
            </Grid>

            {/* Departure Address Full Page Modal */}
            <Modal
                open={departureModalOpen}
                onClose={() => setDepartureModalOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    width: '100vw',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    zIndex: 1300,
                }}
            >
                {/* Full-screen white background */}
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        padding: 0,
                        backgroundColor: 'white',
                        margin: 0,
                        borderRadius: 0,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center', // Center the inner box horizontally
                        alignItems: 'flex-start',  // Align the inner content at the top
                    }}
                >
                    {/* Inner Box with maxWidth of 750px */}
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '750px',
                            margin: '0 auto',
                            padding: '16px',  // Add padding around the content
                            boxSizing: 'border-box',
                        }}
                    >
                        {/* Header Section */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '16px',
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                From where?
                            </Typography>
                            {/* Close Icon */}
                            <IconButton
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                onClick={() => setDepartureModalOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {/* Search Input Component */}
                        <SearchInput
                            ref={departureInputRef}
                            placeholder="Search departure location"
                            address={departureAddress}
                            handleAddressChange={({ address, name, place, state, type }) => {
                                setDepartureAddress(address);
                                setDepartureName(name);
                                setDepartureCity(place);
                                setDepartureState(state);
                                setDepartureType(type);
                            }}
                            mode={mode}
                            onItemClick={() => {
                                setDepartureModalOpen(false);
                            }}
                            enableHistory = {enableHistory}
                        />
                    </Box>
                </Box>
            </Modal>

            {/* Arrival Address Full Page Modal */}
            <Modal
                open={arrivalModalOpen}
                onClose={() => setArrivalModalOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    width: '100vw',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    zIndex: 1300,
                }}
            >
                {/* Full-screen background */}
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        padding: 0,
                        backgroundColor: 'white',
                        margin: 0,
                        borderRadius: 0,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',  // Centers the inner box horizontally
                        alignItems: 'flex-start',   // Align the inner content at the top
                    }}
                >
                    {/* Inner content box with maxWidth of 750px */}
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '750px',  // Limit the content to 750px width
                            margin: '0 auto',
                            padding: '16px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {/* Header with title and close icon */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom: '16px',
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                To where?
                            </Typography>
                            {/* Close Icon */}
                            <IconButton
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                onClick={() => setArrivalModalOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {/* Search Input for Arrival */}
                        <SearchInput
                            ref={arrivalInputRef}
                            placeholder="Search arrival location"
                            address={arrivalAddress}
                            handleAddressChange={({ address, name, place, state, type }) => {
                                setArrivalAddress(address);
                                setArrivalName(name);
                                setArrivalCity(place);
                                setArrivalState(state);
                                setArrivalType(type);
                            }}
                            mode={mode}
                            onItemClick={() => {
                                setArrivalModalOpen(false);
                            }}
                            enableHistory = {enableHistory}
                        />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default SearchBar;

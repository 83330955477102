import React, { useEffect } from 'react';
import RideItem from './RideItem';
import { Box, Typography } from '@mui/material';

function RidesList({ segmentMatches, mode = "Rides" }) {
    // Move useEffect to the top level of the component, before any conditional returns
    useEffect(() => {
        if (Array.isArray(segmentMatches)) {
            console.log("segmentMatches: ", segmentMatches);
        }
    }, [segmentMatches]);

    // Check if rides array is empty and render early if needed
    if (!segmentMatches) {
        return (
            <Box sx={{ marginBottom: '100px' }}>
                <Typography variant="h6" color="text.primary" align="center">
                    No Rides Found
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,   // No padding
                width: '100%',  // Fill the entire width
            }}
        >
            {Array.isArray(segmentMatches) && segmentMatches.length > 0 ? (
                segmentMatches.map((segmentGroup) => (
                    <RideItem
                        key={segmentGroup.ride_id}
                        segmentGroup={segmentGroup}
                        mode={mode}
                    />
                ))
            ) : (
                <Box sx={{ marginBottom: '100px' }}>
                    <Typography variant="body1" color="text.primary" align="center">
                        No Rides Found
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default RidesList;

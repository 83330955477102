import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography } from '@mui/material'; // MUI components
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // Steering wheel icon
import StarIcon from '@mui/icons-material/Star';
import apiConfig from "../../../config/apiConfig";

function RideItemDriverInfo({ driver_id }) {
    const [driverInfo, setDriverInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [averageRating, setAverageRating] = useState(null); // New state for average rating

    // Fetch driver information when the component mounts
    useEffect(() => {
        const fetchDriverInfo = async () => {
            try {
                // Fetch driver profile data
                const response = await fetch(`${apiConfig.baseUrl}/api/profile/get-user-profile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: driver_id }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch driver info');
                }

                const data = await response.json();
                setDriverInfo(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        const fetchDriverRating = async () => {
            try {
                // Fetch driver review data (average rating and review count)
                const response = await fetch(`${apiConfig.baseUrl}/api/reviews/get-driver-review-data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: driver_id }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch driver rating');
                }

                const { averageRating } = await response.json();
                setAverageRating(averageRating); // Set average rating
            } catch (err) {
                setError(err.message);
            }
        };
        // Fetch both driver info and driver rating
        fetchDriverInfo();
        fetchDriverRating();
    }, [driver_id]);

    if (loading) {
        return <p>Loading driver info...</p>;
    }

    if (error) {
        return <p>Error loading driver info: {error}</p>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%', // Takes up the entire area
                borderBottomLeftRadius: '32px', // Standard rounding for bottom left corner
                borderBottomRightRadius: '32px', // Standard rounding for bottom right corner
                overflow: 'hidden', // Ensures content stays within bounds if necessary
                padding: 2, // Add padding for spacing around content
                backgroundColor: 'secondary.main',
                boxSizing: 'border-box' // Ensures the box model works as expected
            }}
        >
            {/* Steering Wheel Icon */}
            <DirectionsCarIcon
                sx={{
                    color: 'primary.main',
                    fontSize: 20,
                    marginRight: 2, // Space between icon and profile picture
                }}
            />

            {/* Driver Profile Picture */}
            <Avatar
                src={`${driverInfo?.profile_picture || 'https://api.ridealong.us/content/profile/default_profile_picture.svg'}?t=${new Date().getTime()}`}
                alt="Driver Profile"
                sx={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%', // Circular profile picture
                    objectFit: 'cover',
                    marginRight: 2,
                }}
            />

            {/* Driver Info (Name, Rating, Verifications) */}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* Driver Name and Rating */}
                    <Typography variant="h7" sx={{ color: 'text.primary' }}>
                        {driverInfo.first_name}
                    </Typography>

                    {/* Dash between name and rating */}
                    <Typography variant="body2" sx={{ marginLeft: 1, marginRight: 1, color: 'text.primary' }}>
                        -
                    </Typography>

                    {/* Driver Rating */}
                    {averageRating ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: 0.5, color: 'text.primary' }}>
                                {averageRating}
                            </Typography>
                            <StarIcon sx={{ fontSize: 16, color: 'primary.dark' }} />
                        </Box>
                    ) : (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            No reviews yet
                        </Typography>
                    )}
                </Box>

                {/* Verified University Student (if applicable) */}
                {driverInfo.verified_student && driverInfo.university_name && (
                    <Typography variant="body2" sx={{ color: 'green'}}>
                        Verified Student
                    </Typography>
                )}

                {/* Verified Driver (if applicable) */}
                {driverInfo.verified_id && (
                    <Typography variant="body2" sx={{ color: 'green'}}>
                        Verified Driver
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default RideItemDriverInfo;

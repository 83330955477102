import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ProfileIDVerificationCard = ({ verified_ID }) => {
    return (
        <Card
            sx={{
                mb: 2,
                boxShadow: 'none', // Remove shadow
                backgroundColor: 'secondary.main', // Background color
                color: 'primary.main', // Set text color to primary
                display: 'flex',
                alignItems: 'center', // Center content vertically
                p: 0,
            }}
        >
            {/* Plus Icon on the left */}
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, ml: 3, color: 'primary.main' }}>
                <AddIcon fontSize="small" />
            </Box>

            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0 !important', // Explicitly set padding
                    color: 'primary.main',
                }}
            >
                <Typography variant="h7">
                    {verified_ID ? (
                        <Box component="span" fontWeight="bold">
                            ID Verified
                        </Box>
                    ) : (
                        <Typography component="span" sx={{ color: 'primary.main' }}>
                            Verify your ID
                        </Typography>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProfileIDVerificationCard;

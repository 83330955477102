import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#243665', // Your custom primary color
            contrastText: '#ffffff',
            dark: '#002d4d', // Generated darker shade of primary
        },
        secondary: {
            main: '#b1d5f1', // Your custom secondary color
            contrastText: '#002d4d',
            dark: '#91B1CD', // Darker shade of secondary
            light:'#e1eff7' //20% opacity over white
        },
        tertiary: {
            main: '#8bd8bd', // Your custom tertiary color
            contrastText: '#002d4d',
            dark: '#91B1CD', // Darker shade of secondary
        },
        quartiary: {
            main: '#FBC490', // Pastel orange
            contrastText: '#002d4d',
            dark: '#F0A55E', // Darker shade of the pastel orange
        },
        success: {
            main: '#4caf50',
            contrastText: '#ffffff',
            dark: '#388E3C',
        },
        warning: {
            main: '#e65100',
            contrastText: '#ffffff',   // Text color for contrast
            dark: '#d32f2f',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: '#002d4d', // Your custom text color
            secondary: '#9AA5B1',
            disabled: '#6E6E6E'
        },
    },
    typography: {
        fontFamily: ['Poppins', 'Open Sans'].join(','),
        h4: {
            fontWeight: 600,
            fontSize: '1.75rem',
            color: '#002d4d', // Link to primary text color
        },
        h5: {
            fontWeight: 500,
            fontSize: '1.5rem',
            color: '#002d4d', // Link to primary text color
        },
        h6: {
            fontWeight: 700,
            fontSize: '1.15rem',
            color: '#002d4d', // Link to primary text color
        },
        h7: {
            fontWeight: 500,
            fontSize: '1rem',
            color: '#002d4d', // Link to primary text color
        },
        button: {
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '1rem',
            color: '#244665', // Keep button text using primary color
        },
        body1: {
            fontFamily: 'Open Sans',
            fontSize: '.9rem',
            color: '#3E5C76',
        },
        body2: {
            fontFamily: 'Open Sans',
            fontSize: '.75rem',
            color: '#3E5C76',
        },
    },
    shape: {
        borderRadius: '16px',
    },
    shadows: [
        'none',
        '0px 1px 3px rgba(36, 70, 101, 0.12), 0px 1px 2px rgba(36, 70, 101, 0.24)',
        ...Array(24).fill('0px 4px 10px rgba(36, 70, 101, 0.12)'),
    ],
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#b1d5f1', // Use secondary color for button background
                    color: '#244665', // Keep the text color as primary
                    '&:hover': {
                        backgroundColor: '#91B1CD', // Darker shade of secondary color on hover
                    },
                    boxShadow: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    boxShadow: '0px 4px 8px rgba(36, 70, 101, 0.1)',
                    borderRadius: 16,
                    '&:hover': {
                        boxShadow: '0px 6px 12px rgba(36, 70, 101, 0.15)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    backgroundColor: '#ffffff',
                    '& .MuiOutlinedInput-root': {
                        boxShadow: 'none',
                        '& fieldset': {
                            borderColor: '#b1d5f1', // Secondary color for text field border
                        },
                        '&:hover fieldset': {
                            borderColor: '#91B1CD', // Darker secondary color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#244665', // Primary color for focus
                        },
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff',
                    color: '#002d4d', // Primary text color
                    boxShadow: 'none', // No shadow
                    '&:hover': {
                        backgroundColor: 'transparent', // No background color change on hover
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    boxShadow: 'none',
                    '& fieldset': {
                        border: 'none',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(36, 70, 101, 0.04)',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'rgba(36, 70, 101, 0.08)',
                    },
                },
            },
        },
    },
});

export default theme;

import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ContinueReservationBar({ segmentGroup, driverDetails }) {
    const navigate = useNavigate();
    const [isFixed, setIsFixed] = useState(true); // Start with fixed positioning
    const [hasStuck, setHasStuck] = useState(false); // New state to track if the bar is "stuck" to the page

    const handleReserveClick = () => {
        navigate('/review', { state: { segmentGroup, driverDetails } });
    };

    useEffect(() => {
        const handleScroll = () => {
            const reservationBar = document.getElementById('reservationBar');
            const rect = reservationBar.getBoundingClientRect();

            // Only change the position if it hasn't stuck to the page yet
            if (!hasStuck) {
                // Check if the bar should switch to relative (reaches its natural location)
                if (rect.top >= window.innerHeight - rect.height) {
                    setIsFixed(false); // Set position to relative
                    setHasStuck(true); // Mark as stuck to prevent further toggling
                } else {
                    setIsFixed(true); // Keep it fixed
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasStuck]); // Only rerun effect when hasStuck changes

    return (
        <Box
            id="reservationBar"
            sx={{
                position: isFixed ? 'fixed' : 'relative',
                bottom: isFixed ? 0 : 'auto',
                left: 0,
                right: 0,
                width: '100%',
                backgroundColor: 'background.paper',
                boxShadow: isFixed ? '0 -2px 5px rgba(0, 0, 0, 0.1)' : 'none', // Remove shadow when stuck
                display: 'flex',
                justifyContent: 'center',
                zIndex: 5,
                paddingY: 2,
                transition: 'position 0.3s ease, box-shadow 0.3s ease', // Smooth transition for position and shadow
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: '750px',
                    paddingX: 2,
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                    Total Price: ${segmentGroup.price}
                </Typography>

                <Box sx={{ marginLeft: 3 }} />

                <Button
                    onClick={handleReserveClick}
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '30px',
                        fontSize: '16px',
                        maxWidth: 'fit-content',
                        minWidth: 'fit-content',
                        '&:hover': {
                            backgroundColor: 'primary.dark',
                            transform: 'translateY(-2px)',
                        },
                        transition: 'background-color 0.3s ease, transform 0.3s ease',
                    }}
                >
                    Continue Reservation
                </Button>
            </Box>
        </Box>
    );
}

export default ContinueReservationBar;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Avatar, Typography, Divider} from '@mui/material'; // Import MUI components
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person'; // Better reflects a passenger
import StarIcon from '@mui/icons-material/Star';
import TagIcon from '@mui/icons-material/Tag'; // Large # icon
import LockIcon from '@mui/icons-material/Lock'; // Padlock icon for verifications
import TuneIcon from '@mui/icons-material/Tune'; // Icon for preferences
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Driver's License icon
import EmailIcon from '@mui/icons-material/Email'; // Email icon
import PhoneIcon from '@mui/icons-material/Phone'; // Phone icon
import SchoolIcon from '@mui/icons-material/School'; // Student Status icon
import ChatIcon from '@mui/icons-material/Chat'; // Conversation icon for preferences
import MusicNoteIcon from '@mui/icons-material/MusicNote'; // Music icon for preferences
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ManagePassengerStatus from "./Components/ManagePassengerStatus";
import apiConfig from "../../config/apiConfig";

function ViewProfile() {
    const location = useLocation();
    const { profile, mode } = location.state || {}; // Destructure the passed state, fallback to an empty object if state is not passed
    const navigate = useNavigate();

    const [averageDriverRating, setAverageDriverRating] = useState(null); // State for average rating
    const [completedRidesDriver, setCompletedRidesDriver] = useState(null); // State for completed rides
    const [averagePassengerRating, setAveragePassengerRating] = useState(null); // State for average rating
    const [completedRidesPassenger, setCompletedRidesPassenger] = useState(null); // State for completed rides
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchDriverRating = async () => {
            try {
                console.log("viewprofile.jsx - profile", profile);
                // Fetch profile review data (average rating and review count)
                const response = await fetch(`${apiConfig.baseUrl}/api/reviews/get-driver-review-data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: profile.profile_id }), // Use profile.profile_id to get the rating
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch driver rating');
                }

                const { averageRating } = await response.json(); // Parse response to get average rating
                console.log("avg rating:", averageRating); // Log before setting state
                setAverageDriverRating(averageRating); // Set the average rating in state
            } catch (err) {
                setError(err.message); // Handle any errors
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        const fetchCompletedRides = async () => {
            try {
                // Fetch the number of completed rides
                const response = await fetch(`${apiConfig.baseUrl}/api/rides/get-completed-rides`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': 'Lelugelelugeleluge1i8uudaf89shfjabfjkbtbjwIOFJA089'
                    },
                    body: JSON.stringify({ profile_id: profile.profile_id }), // Use profile.profile_id for the request
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch completed rides');
                }

                const { completedRides } = await response.json(); // Get the number of completed rides
                setCompletedRidesDriver(completedRides); // Set the completed rides in state
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDriverRating(); // Fetch the driver rating
        fetchCompletedRides(); // Fetch the number of completed rides
    }, [profile.profile_id]);

    // If still loading, return a styled "Loading..." message
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography sx={{ marginLeft: 2, fontWeight: 'bold' }}>Loading...</Typography>
            </Box>
        );
    }

    // If there's an error, return a styled error message
    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>Error Accessing Driver Data: {error}</Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: '800px',
                margin: '0 auto',
                padding: 3,
                backgroundColor: 'background.paper',
                borderRadius: 2,
                position: 'relative',
            }}
        >
            <IconButton
                onClick={() => navigate(-1)} // Navigate back to the previous page
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    color: 'primary.main', // Adjust the color as needed
                    '&:hover': {
                        backgroundColor: 'transparent', // No background on hover
                    },
                }}
            >
                <ArrowBackIcon sx={{ fontSize: 30 }} />
            </IconButton>

            {/* Conditionally render ManagePassengerStatus if mode is "Passenger View" */}
            {mode === "Passenger View" && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', // Center the component horizontally
                        marginY: 4, // 2 units of spacing above and below
                    }}
                >
                    <ManagePassengerStatus profile={profile} /> {/* Pass the profile data to ManagePassengerStatus component */}
                </Box>
            )}

            {/* Profile Picture and Name */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 3,
                }}
            >
                <Avatar
                    src={profile.profile_picture}
                    alt={`${profile.first_name}`}
                    sx={{
                        width: 120,
                        height: 120,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: '3px solid',
                        borderColor: 'primary.main',
                        marginRight: 3,
                    }}
                />
                <Box>
                    <Typography variant="h4" sx={{ marginBottom: 1 }}>
                        {profile.first_name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {profile.bio_text}
                    </Typography>
                </Box>
            </Box>

            {/* Verifications Section */}
            <Box sx={{ marginBottom: 3 }}>
                {/* Verifications Header */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <LockIcon sx={{ fontSize: 24, marginRight: 1 }} />
                    <Typography variant="h6" color="primary">
                        Verifications
                    </Typography>
                    <Divider
                        sx={{
                            flexGrow: 1,
                            marginLeft: 2,
                            borderColor: 'primary.main',
                        }}
                    />
                </Box>

                {/* Driver's License */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <CreditCardIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    <Typography variant="body1">
                        <strong>Driver's License:</strong>{' '}
                        <Typography
                            component="span"
                            sx={{ color: profile.verified_id ? 'success.main' : 'text.secondary' }}
                        >
                            {profile.verified_id ? 'Verified' : 'Not Verified'}
                        </Typography>
                    </Typography>
                </Box>

                {/* Email */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <EmailIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    <Typography variant="body1">
                        <strong>Email:</strong>{' '}
                        <Typography
                            component="span"
                            sx={{ color: profile.verified_email ? 'success.main' : 'text.secondary' }}
                        >
                            {profile.verified_email ? 'Verified' : 'Not Verified'}
                        </Typography>
                    </Typography>
                </Box>

                {/* Phone */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <PhoneIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    <Typography variant="body1">
                        <strong>Phone:</strong>{' '}
                        <Typography
                            component="span"
                            sx={{ color: profile.verified_phone ? 'success.main' : 'text.secondary' }}
                        >
                            {profile.verified_phone ? 'Verified' : 'Not Verified'}
                        </Typography>
                    </Typography>
                </Box>

                {/* Student Status (only display if relevant) */}
                {profile.verified_student && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                        <SchoolIcon sx={{ fontSize: 20, marginRight: 1 }} />
                        <Typography variant="body1">
                            <strong>Student Status:</strong>{' '}
                            <Typography
                                component="span"
                                sx={{ color: profile.verified_student ? 'success.main' : 'text.secondary' }}
                            >
                                {profile.verified_student ? 'Verified' : 'Not Verified'}
                            </Typography>
                        </Typography>
                    </Box>
                )}
            </Box>

            {/* Ride Stats - Driver and Passenger Ratings */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    marginY: 3,
                }}
            >
                {/* Driver Rating - Show only if driver data exists */}
                {(averageDriverRating !== null && averageDriverRating > 0) || (completedRidesDriver !== null && completedRidesDriver > 0) ? (
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <DirectionsCarIcon sx={{ fontSize: 24, marginRight: 1 }} />
                            <Typography variant="h6" color="primary">
                                Driver Rating
                            </Typography>
                            <Divider
                                sx={{
                                    flexGrow: 1,
                                    marginLeft: 2,
                                    borderColor: 'primary.main',
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <StarIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                                <Typography variant="h7" color="text.primary">
                                    {averageDriverRating !== null && averageDriverRating > 0 ? `${averageDriverRating} / 5` : 'No rating'}
                                </Typography>
                            </Box>

                            <Box sx={{ textAlign: 'center' }}>
                                <TagIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                                <Typography variant="h7" color="text.primary">
                                    {completedRidesDriver !== null && completedRidesDriver > 0 ? `${completedRidesDriver} rides completed` : 'No rides completed'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : null}

                {/* Passenger Rating - Show only if passenger data exists */}
                {(averagePassengerRating !== null && averagePassengerRating > 0) || (completedRidesPassenger !== null && completedRidesPassenger > 0) ? (
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <PersonIcon sx={{ fontSize: 24, marginRight: 1 }} />
                            <Typography variant="h6" color="primary">
                                Passenger Rating
                            </Typography>
                            <Divider
                                sx={{
                                    flexGrow: 1,
                                    marginLeft: 2,
                                    borderColor: 'primary.main',
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <StarIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                                <Typography variant="h7" color="text.primary">
                                    {averagePassengerRating !== null && averagePassengerRating > 0 ? `${averagePassengerRating} / 5` : 'No rating'}
                                </Typography>
                            </Box>

                            <Box sx={{ textAlign: 'center' }}>
                                <TagIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                                <Typography variant="h7" color="text.primary">
                                    {completedRidesPassenger !== null && completedRidesPassenger > 0 ? `${completedRidesPassenger} rides completed` : 'No rides completed'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : null}
            </Box>

            {/* Preferences Section */}
            <Box sx={{ marginBottom: 3 }}>
                {/* Preferences Header */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <TuneIcon sx={{ fontSize: 24, marginRight: 1, color: 'primary' }} />
                    <Typography variant="h6" color="primary">
                        Preferences
                    </Typography>
                    <Divider
                        sx={{
                            flexGrow: 1,
                            marginLeft: 2,
                            borderColor: 'primary.main',
                        }}
                    />
                </Box>

                {/* Preferences Content */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <ChatIcon sx={{ fontSize: 20, marginRight: 1, color: 'primary' }} />
                    <Typography variant="body1" color="primary">
                        <strong>Conversation:</strong> {profile.preference_conversation}
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <MusicNoteIcon sx={{ fontSize: 20, marginRight: 1, color: 'primary' }} />
                    <Typography variant="body1" color="primary">
                        <strong>Music:</strong> {profile.preference_music}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default ViewProfile;
